const GridToolbarIds = {
    Excel: 'Excel',
    ClearFilter: 'ClearFilter',
    Add: "Add",
    Edit: "Edit",
    Delete: "Delete",
    Refresh: "Refresh",
    Reset: 'Reset',
    Clone: "Clone",
    View: 'View',
    Save: 'Save',
    Cancel: 'Cancel',
    Print: 'Print',
    AddOktaTile: "AddOktaTile",
    RemoveOktaTile: "RemoveOktaTile",
    CurrentWeek: 'CurrentWeek'
};

export default GridToolbarIds;