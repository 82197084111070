import { Toast as SfToast } from '@syncfusion/ej2-notifications';

const ToastType = {
    Success: "e-toast-success",
    Info: "e-toast-info",
    Warning: "e-toast-warning",
    Error: "e-toast-danger",
};

function showToast(text, type) {
    if (!text || text === '') return;
    let toast = new SfToast({
        content: text,
        cssClass: type ?? "",
        position: { X: 'Right', Y: 'Top' },
        timeOut: type == ToastType.Error ? 30000 : 5000,
        showCloseButton: true,
        click: function (args) {
            args.clickToClose = true;
        },
        animation: {
            hide: {
                duration: 0
            }
        }
    });
    toast.appendTo('#toaster');
    toast.show();
}

function clear() {
    document.getElementById('toaster').ej2_instances?.forEach(x => {
        x.hide('All');
    });
}

const Toast = {
    Success: (text) => {
        showToast(text, ToastType.Success);
    },
    Info: (text) => {
        showToast(text, ToastType.Info);
    },
    Warning: (text) => {
        showToast(text, ToastType.Warning);
    },
    Error: (text) => {
        showToast(text, ToastType.Error);
    },
    Clear: () => {
        clear();
    }
};

export default Toast;