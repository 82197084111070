<template>
    <ejs-grid v-show="!mismatch" v-bind="$attrs" ref='grid' 
              cssClass="d16-grid"
              :toolbar="grid.toolbar"
              @toolbarClick="grid.toolBarClick"
              :dataSource="grid.dataSource"
              :allowSearching="grid.allowSearching"
              :allowReordering="allowReordering"
              :allowPaging="allowPaging ?? grid.allowPaging"
              :pageSettings="pageSettings ?? grid.pageSettings"
              :allowSorting="allowSorting ?? grid.allowSorting"
              :sortSettings="sortSettings"
              :showColumnChooser="showColumnChooser"
              :enablePersistence="enablePersistence"
              :allowMultiSorting="allowMultiSorting ?? grid.allowMultiSorting"
              :allowFiltering="allowFiltering ?? grid.allowFiltering"
              :enableStickyHeader="enableStickyHeader ?? grid.enableStickyHeader"
              :clipMode="grid.clipMode"
              :selectionSettings="grid.selectionSettings"
              :filterSettings="filterSettings ?? grid.filterSettings"
              @created="grid.filterFill"
              @actionComplete="grid.filterFill"
              :editSettings="editSettings ?? grid.editSettings"
              @dataBound="grid.dataBound"
              @recordDoubleClick="grid.recordDoubleClick"
              :allowExcelExport='true'
              :allowResizing="true"
              :aggregates='aggregates'
              :columns="columns"
              :loadingIndicator="grid.loadingIndicator"
              @actionBegin="grid.actionBegin">
    </ejs-grid>
    <div class="mismatch-overlay d-flex align-middle align-items-center justify-content-center" v-if="mismatch" v-on:click="resetGrid">
        <div class="mismatch-overlay-inner">
            Configuration of this grid has changed since the last time you visited this page. Please click <a href="#">here</a> to re-render the grid.
        </div>
    </div>
</template>

<script>

    import { GridComponent, CommandColumn, Aggregate } from '@syncfusion/ej2-vue-grids';
    import GridToolbarIds from "../../constants/GridToolbarIds";

    export default {
        name: 'GridComp',
        inheritAttrs: false,
        components: {
            'ejs-grid': GridComponent,
        },
        provide: {
            grid: [CommandColumn, Aggregate]
        },
        props: {
            dataSource: Array,
            toolbar: Array,
            toolbarOverride: Boolean,
            editSettings: Object,
            filterSettings: Object,
            allowFiltering: Boolean(true),
            allowPaging: Boolean(true),
            allowSorting: Boolean(true),
            allowMultiSorting: Boolean(true),
            sortSettings: Object,
            aggregates: Array,
            showColumnChooser: Boolean(true),
            enablePersistence: Boolean(false),
            parent: String() ?? "",
            pageSettings: Object,
            commands: Array,
            toolbarClick: Function,
            enableStickyHeader: Boolean(true),
            columns: Array,
            allowReordering: Boolean(false)
        },
        watch: {
            dataSource: {
                handler(newDs) {
                    if (this.grid.dataSource == newDs && !Array.isArray(newDs)) {
                        return;
                    }
                    this.grid.dataSource = newDs;
                    if (this.$refs.grid != null) {
                        this.$refs.grid.ej2Instances.dataSource = newDs;
                    }
                },
                deep: true
            }
        },
        expose: ['update', 'refresh'],
        data() {
            return {
                mismatch: false,
                grid: {
                    dataSource: [],
                    toolbar: [
                        { text: 'Excel Export', tooltipText: 'Excel Export', id: GridToolbarIds.Excel, prefixIcon: 'e-excelexport' },
                        { text: GridToolbarIds.Reset, tooltipText: GridToolbarIds.Reset, id: GridToolbarIds.Reset, prefixIcon: 'e-reset', align: 'Right' },
                        "ColumnChooser",
                        { text: 'Clear Filter', tooltipText: 'Clear Filter', id: GridToolbarIds.ClearFilter, prefixIcon: 'e-delete-3', align: 'Right' },
                        "Search",
                    ],
                    selectedRow: null,
                    selectionSettings: {
                        enableToggle: false
                    },
                    enableToolbar: false,
                    allowPaging: true,
                    pageSettings: {
                        pageSize: 15,
                        pageSizes: [10, 15, 25, 50, "All"],
                        pageCount: 5,
                        currentPage: 1
                    },
                    editSettings: {allowAdding: false, allowEditing: false, allowDeleting: false},
                    allowSearching: true,
                    allowSorting: true,
                    allowMultiSorting: true,
                    allowFiltering: true,
                    filterSettings: {
                        type: GridToolbarIds.Excel
                    },
                    loadingIndicator: { indicatorType: 'Shimmer' },
                    clipMode: 'EllipsisWithTooltip',
                    enableStickyHeader: true,
                    filterFill: (args) => {
                        if (args?.requestType === 'filtering') {
                            let headerCells = this.$refs.grid.getHeaderTable().getElementsByClassName('e-headercell');
                            Array.from(headerCells).forEach(cell => {
                                cell.classList.remove('filterFill');
                            });

                            args.columns.forEach(col => {
                                let index = this.$refs.grid.getColumnIndexByField(col.properties.field);
                                var cell = headerCells[index];
                                cell.classList.add('filterFill');
                            });
                        }
                    },
                    rowSelected: (args) => {
                        this.grid.selectedRow = args.data;
                    },
                    rowDeselected: () => {
                        this.grid.selectedRow = null;
                    },
                    toolBarClick: (args) => {
                        if (args.item.id === GridToolbarIds.ClearFilter) {
                            this.$refs.grid.clearFiltering();
                            this.$refs.grid.search('');
                        }
                        if (args.item.id === GridToolbarIds.Excel) {
                            this.$refs.grid.excelExport();
                        }

                        if (args.item.id === GridToolbarIds.Reset) {
                            this.resetGrid();
                        }
                    },
                    dataBound: () => {
                        this.$refs.grid.autoFitColumns();

                        if (this.columns.length !== this.$refs.grid.ej2Instances.columns.length) {
                            this.mismatch = true;
                            return;
                        }

                        this.mismatch = this.columns.map(x => x.field).some(x => !this.$refs.grid.ej2Instances.columns.map(y => y.field).includes(x)) || this.$refs.grid.ej2Instances.columns.map(x => x.field).some(x => !this.columns.map(y => y.field).includes(x));


                        if (this.$refs.grid.ej2Instances.filterSettings.properties.columns) {
                            let filterColumns = this.$refs.grid.ej2Instances.filterSettings.properties.columns.map(x => x.properties.field).filter((value, index, array) => {
                                return array.indexOf(value) === index;
                            });

                            let headerCells = this.$refs.grid.getHeaderTable().getElementsByClassName('e-headercell');

                            filterColumns.forEach(x => {
                                let index = this.$refs.grid.getColumnIndexByField(x);
                                var cell = headerCells[index];
                                cell.classList.add('filterFill');
                            })
                        }
                    },
                    actionBegin: (args) => {
                        if (args.requestType === "filterchoicerequest") {
                            args.filterChoiceCount = 1000000;
                        }
                    }
                },
            };
        },
        mounted() {
            
        },
        created() {
			if (this.toolbarOverride === true) {
				this.grid.toolbar = this.toolbar;
			}
            else if (Array.isArray(this.toolbar)) {
                this.grid.toolbar = this.toolbar.concat(this.grid.toolbar);
            }

            if (this.grid.toolbar) {
				if (this.enablePersistence !== true) {
					this.grid.toolbar = this.grid.toolbar.filter(x => x.id != GridToolbarIds.Reset);
				}
				if (this.showColumnChooser !== true) {
					this.grid.toolbar = this.grid.toolbar.filter(x => x != 'ColumnChooser');
				}
            }

            if (this.dataSource != null) {
                this.grid.dataSource = this.dataSource;
            }
        },
        methods: {
            update: function (data) {
                this.grid.dataSource = data;
                this.$refs.grid.ej2Instances.dataSource = data;
            },
            refresh: function () {
                this.$refs.grid.ej2Instances.refresh();
            },
            resetGrid: function () {
                let id = this.$refs.grid.ej2Instances.element.id;

                if (document.querySelector(".mismatch-overlay")) {
                    document.querySelector(".mismatch-overlay").style.height = document.querySelector(".mismatch-overlay").clientHeight + "px";
                }
                this.$refs.grid.ej2Instances.destroy();
                localStorage.removeItem('grid' + id);

                window.location.reload(false);
            },
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
