<template>
    <div v-if="dialogVisible">
        <ejs-dialog ref="newHourDialog" :position="position" :visible="true" :isModal="true" :buttons="buttons" :header="header" :width="500" @beforeClose="beforeClose">
            <Spinner v-show="saving" message="Saving"></Spinner>
            <div v-show="!saving">
                <div class="row mb-2">
                    <div class="col label-text">Technician: </div>
                    <div class="col">
                        <ejs-dropdownlist ref="ddlNewHoursUser"
                                          v-if="SiteUser?.Abilities?.CanCoordinate"
                                          placeholder="Technician"
                                          v-model:value="newHour.UserId"
                                          :dataSource="users.dataSource"
                                          :query="users.query"
                                          :fields="users.fields"
                                          :allowFiltering="true"
                                          filterType="Contains"
                                          popupWidth="Auto"
                                          :itemTemplate="'userTemplate'">
                            <template v-slot:userTemplate="{data}">
                                <UserTemplate :userInfo="data" />
                            </template>
                        </ejs-dropdownlist>
                        <ejs-textbox v-else :value="SiteUser.FullName" :enabled="false"></ejs-textbox>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col label-text">Date: </div>
                    <div class="col">
                        <ejs-datepicker v-model:value="newHour.Date" :max="new Date()"></ejs-datepicker>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col label-text">Actual Hours: </div>
                    <div class="col">
                        <ejs-numerictextbox ref="refActualHours" :showSpinButton="false" :min="0" :max="1000" format="N2" :decimals="2" v-model:value="newHour.ActualHours"></ejs-numerictextbox>
                    </div>
                </div>
            </div>
        </ejs-dialog>
    </div>
</template>

<script>
    import Toast from "../../modules/Toast.js";
    import { Query } from "@syncfusion/ej2-data";
    import { DialogComponent } from "@syncfusion/ej2-vue-popups";
    import { DatePickerComponent } from "@syncfusion/ej2-vue-calendars";
    import { TextBoxComponent, NumericTextBoxComponent } from "@syncfusion/ej2-vue-inputs";
    import { DropDownListComponent } from '@syncfusion/ej2-vue-dropdowns';
    import UserTemplate from '../templates/UserTemplate.vue';
    import Spinner from '../shared/Spinner.vue';

    export default {
        name: 'AddHours',
        inject: ['state', 'ApiClient'],
        expose: ['show'],
        emits: ['added'],
        props: {
            modelShopTrackerId: Number,
            jobNumber: Number
        },
        components: {
            'ejs-dialog': DialogComponent,
            'ejs-numerictextbox': NumericTextBoxComponent,
            'ejs-textbox': TextBoxComponent,
            'ejs-datepicker': DatePickerComponent,
            'ejs-dropdownlist': DropDownListComponent,
            UserTemplate,
            Spinner,
        },
        data() {
            return {
                isNew: false,
                dialogVisible: false,
                position: { X: "center", Y: "top" },
                newHour: {
                    UserId: this.state.data.SiteUser?.Id,
                    Date: new Date(),
                    ActualHours: 0,
                    ModelShopTrackerId: this.$props.modelShopTrackerId
                },
                saving: false
            }
        },
        mounted() {
        },
        computed: {
            SiteUser() {
                return this.state.data.SiteUser;
            },
            users() {
                return {
                    dataSource: this.ApiClient.DataSource("Listing/Operators"),
                    fields: { text: "FullName", value: "Id" },
                    query: new Query().addParams('SpecificId', this.newHour.UserId).take(50),
                };
            },
            header() {
                return this.isNew ? "Add Hours" : "Edit Hours";
            },
            buttons() {
                return [
                    {
                        click: this.addHours, buttonModel: { content: 'Save', iconCss: 'fa fa-save', cssClass: "e-success", isPrimary: true, disabled: this.saving }
                    },
                    {
                        click: this.closeDialog, buttonModel: { content: 'Close' }
                    }
                ];
            }
        },
        methods: {
            show: function (newHour) {
                if (newHour) {
                    this.isNew = false;
                    this.ApiClient.Get('Hour/' + newHour.Id, (response) => {
                        this.newHour = response;
                        this.dialogVisible = true;
                    });
                }
                else {
                    this.isNew = true;
                    this.ApiClient.Get('Hour/New/' + this.$props.jobNumber, (response) => {
                        this.newHour = response;
                        this.dialogVisible = true;
                    });
                }
            },
            addHours: function () {
                if (!(this.$refs.refActualHours.ej2Instances.value > 0)) {
                    Toast.Warning("Please enter the actual hours worked.")
                    return;
                }
                else if (this.SiteUser?.Abilities?.CanCoordinate && !(this.$refs.ddlNewHoursUser.ej2Instances.value > 0)) {
                    Toast.Warning("Please select a technician.")
                    return;
                }
                this.saving = true;
                if (this.isNew) {
                    this.ApiClient.Post("Hour/" + this.jobNumber, this.newHour, (response) => {
                        this.$emit('added', response);
                        this.closeDialog();
                    }, () => {
                        this.saving = false;
                    });
                }
                else {
                    this.ApiClient.Put("Hour/" + this.jobNumber, this.newHour, (response) => {
                        this.$emit('added', response);
                        this.closeDialog();
                    }, () => {
                        this.saving = false;
                    });
                }
            },
            closeDialog: function () {
                this.dialogVisible = false;
            },
            beforeClose: function () {
                this.closeDialog();
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
