<template>
    <div v-if="dialogVisible">
        <ejs-dialog ref="saveJobDialog" :position="position" :visible="true" :isModal="true" :buttons="buttons" :header="header" :width="600" @beforeClose="beforeClose">
            <div>Are you sure you want to update Job {{jobNumber}}?</div>
        </ejs-dialog>
    </div>
</template>

<script>
    import Toast from "../../modules/Toast.js";
    import { DialogComponent } from "@syncfusion/ej2-vue-popups";

    export default {
        name: 'SaveJob',
        expose: ['show', 'close'],
        emits: ['confirmed', 'canceled'],
        props: {
            jobNumber: Number
        },
        components: {
            'ejs-dialog': DialogComponent
        },
        data() {
            return {
                dialogVisible: false,
                position: { X: "center", Y: "top" },
                saving: false,
                header: 'Update Job'
            }
        },
        mounted() {
        },
        computed: {
            buttons() {
                return [
                    {
                        click: this.confirmSave, buttonModel: { content: 'Save', iconCss: 'fa fa-save', cssClass: "e-success", isPrimary: true, disabled: this.saving }
                    },
                    {
                        click: this.close, buttonModel: { content: 'Cancel' }
                    }
                ];
            }
        },
        methods: {
            show: function () {
                this.saving = false;
                this.dialogVisible = true;
            },
            confirmSave: function() {
                this.saving = true;
                Toast.Info("Updating Job " + this.jobNumber);
                this.$emit('confirmed');
            },
            close: function () {
                this.dialogVisible = false;
                this.$emit('canceled');
            },
            beforeClose: function () {
                this.close();
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
