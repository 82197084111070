<template>
    <Spinner v-if="loading"></Spinner>
    <div v-else class="card mb-2">
        <div class="card-header">
            {{Header}}
        </div>
        <div class="card-body">
            <div class="mb-4 gx-2 d-flex justify-content-between align-items-end">
                <div></div>
                <div class="alert alert-danger text-center mb-0" style="font-size: 14px;">
                    Please print this job using the link on the right, sign in and deliver it to the incoming work bench in the model shop
                </div>
                <div class="d-flex gx-2 justify-content-end align-items-end">
                    <ejs-button v-if="Job.CanCancel && !viewonly" v-on:click="cancel" class="ms-2">Cancel</ejs-button>
                    <ejs-button v-if="Job.CanUncancel && !viewonly" v-on:click="uncancel" class="ms-2">Un Cancel</ejs-button>
                    <ejs-button v-if="!create" v-on:click="print" class="ms-2">Print</ejs-button>
                    <ejs-button v-on:click="backToList" class="ms-2">Back to List</ejs-button>
                </div>
            </div>
            <div id="form-element">
                <div class="row mb-2 gx-2">
                    <div class="col-lg-2 col-md-4 col-sm-4 col-xs-6">
                        <div class="labeled-textbox">
                            <label>Job #</label>
                            <ejs-textbox placeholder="#" v-model:value="Job.JobNumber" ref="jobNumber" :disabled="true"></ejs-textbox>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-4 col-xs-6">
                        <div class="labeled-textbox">
                            <label>Status</label>
                            <ejs-textbox placeholder="Active" v-model:value="Job.StatusDisplay" ref="status" :disabled="true"></ejs-textbox>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-4 col-xs-6">
                        <div class="labeled-textbox">
                            <label>
                                Part #
                                <RequiredField fieldName="Part #" />
                            </label>
                            <ejs-textbox ref="txtPartNumber" placeholder="#" v-model:value="Job.PartNumber" :enabled="CanEdit" name="partNumber"></ejs-textbox>
                            <div id="error-element-partNumber"></div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-4 col-xs-6">
                        <div class="labeled-textbox">
                            <label>
                                Revision
                                <RequiredField fieldName="Revision" />
                            </label>
                            <ejs-textbox ref="txtRevision" placeholder="--" v-model:value="Job.Revision" name="revision" :enabled="CanEdit"></ejs-textbox>
                            <div id="error-element-revision"></div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-4 col-xs-6">
                        <div class="labeled-textbox">
                            <label>
                                Quantity
                                <RequiredField fieldName="Quantity" />
                            </label>
                            <ejs-numerictextbox ref="txtQuantity" placeholder="#" :min="0" format="###" :step="1" :decimals="0" v-model:value="Job.Quantity" name="quantity" :showSpinButton="false" :enabled="CanEdit"></ejs-numerictextbox>
                            <div id="error-element-quantity"></div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-4 col-xs-6">
                        <div class="labeled-textbox">
                            <label>Part Description</label>
                            <ejs-textbox ref="txtPartDescription" placeholder="--" v-model:value="Job.PartDescription" name="partDescription" :enabled="CanEdit"></ejs-textbox>
                            <div id="error-element-partDescription"></div>
                        </div>
                    </div>
                </div>
                <div class="row mb-2 gx-2">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <div class="labeled-dropdown">
                            <label>
                                Work Type
                                <RequiredField fieldName="Work Type" />
                            </label>
                            <ejs-textbox v-if="viewonly" placeholder="Select Work Type" :value="Job.WorkType?.Type" :enabled="false"></ejs-textbox>
                            <ejs-dropdownlist v-else ref="ddlWorkType" placeholder="Select Work Type" name="workType" :enabled="CanEdit" v-model:value="Job.WorkTypeId" :dataSource="workTypes.dataSource" :query="workTypes.query" :fields="workTypes.fields" :allowFiltering="true" filterType="Contains" @change="workTypes.change" popupWidth="Auto" sortOrder='Ascending'></ejs-dropdownlist>
                            <div id="error-element-workType"></div>
                        </div>
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                        <div class="labeled-textbox">
                            <label>CMM Contacts</label>
                            <ejs-textbox v-if="viewonly" placeholder="Select Some Options" :value="CmmContactsDisplay" :enabled="false"></ejs-textbox>
                            <ejs-multiselect ref="multiCmmContacts"
                                             v-else
                                             :dataSource="cmmContacts.dataSource"
                                             :query="cmmContacts.query"
                                             :allowFiltering="true"
                                             filterType="Contains"
                                             :fields="cmmContacts.fields"
                                             v-model:value="Job.CmmContacts"
                                             :enabled="CmmContactsEnabled"
                                             :closePopupOnSelect="false"
                                             popupWidth="Auto"
                                             mode="Box"
                                             placeholder="Select Some Options"
                                             @filtering="cmmContacts.filtering"
                                             :itemTemplate="'userTemplate'">
                                <template v-slot:userTemplate="{data}">
                                    <UserTemplate :userInfo="data" />
                                </template>
                            </ejs-multiselect>
                        </div>
                    </div>
                </div>
                <div class="row mb-2 gx-2">
                    <div class="col-lg-4 col-md-6 col-sm-8 col-xs-12">
                        <div class="labeled-dropdown">
                            <label>
                                Requestor
                                <RequiredField fieldName="Requestor" />
                            </label>
                            <div class="site-btn-group">
                                <ejs-textbox v-if="viewonly" placeholder="Select Requestor" :value="Job.Requestor?.FullName" :enabled="false"></ejs-textbox>
                                <ejs-dropdownlist ref="ddlRequestor"
                                                  v-else
                                                  placeholder="Select Requestor"
                                                  name="requestor"
                                                  :enabled="CanEdit"
                                                  v-model:value="Job.RequestorId"
                                                  :dataSource="requestors.dataSource"
                                                  :query="requestors.query"
                                                  :fields="requestors.fields"
                                                  @filtering="requestors.filtering"
                                                  :allowFiltering="true"
                                                  filterType="Contains"
                                                  popupWidth="Auto"
                                                  :itemTemplate="'userTemplate'">
                                    <template v-slot:userTemplate="{data}">
                                        <UserTemplate :userInfo="data" />
                                    </template>
                                </ejs-dropdownlist>
                                <ejs-button ref="btnAddUser" v-if="!viewonly" :disabled="!CanEdit" v-on:click="addUserClicked">Add User</ejs-button>
                            </div>
                            <div id="error-element-requestor"></div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-4 col-xs-12">
                        <div class="labeled-date">
                            <label>
                                Due Date
                                <RequiredField fieldName="Due Date" />
                            </label>
                            <ejs-datepicker ref="dtpDateDue" v-model:value="Job.DateDue" name="dateDue" :min="MinDateDue" :openOnFocus="true" :enabled="CanEditDates" />
                            <div id="error-element-dateDue"></div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12 col-sm-12 xol-xs-12">
                        <div class="labeled-dropdown">
                            <label>
                                Inspection
                                <RequiredField fieldName="Inspection" />
                            </label>
                            <ejs-textbox v-if="viewonly" placeholder="Select Inspection" :value="Job.Inspection?.Text" :enabled="false"></ejs-textbox>
                            <ejs-dropdownlist v-else ref="ddlInspection" placeholder="Select Inspection" name="inspection" :enabled="CanEdit" v-model:value="Job.InspectionId" :dataSource="inspections.dataSource" :query="inspections.query" :fields="inspections.fields" :allowFiltering="true" filterType="Contains" popupWidth="Auto" sortOrder='Ascending'></ejs-dropdownlist>
                            <div id="error-element-inspection"></div>
                        </div>
                    </div>
                </div>
                <div class="row mb-2 gx-2">
                    <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                        <div class="labeled-textbox">
                            <label>
                                Charge #
                                <RequiredField fieldName="Charge #" />
                            </label>
                            <ejs-textbox ref="txtChargeNumber" placeholder="#" name="chargeNumber" v-model:value="Job.ChargeNumber" :enabled="CanEdit"></ejs-textbox>
                            <div id="error-element-chargeNumber"></div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                        <div class="labeled-textbox">
                            <label>
                                Contact Number
                                <RequiredField fieldName="Contact Number" />
                            </label>
                            <ejs-textbox ref="txtContactNumber" placeholder="#" name="contactNumber" v-model:value="Job.ContactNumber" :enabled="CanEdit"></ejs-textbox>
                            <div id="error-element-contactNumber"></div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div class="labeled-dropdown">
                            <label>
                                Serialization
                                <RequiredField fieldName="Serialization" />
                            </label>
                            <ejs-textbox v-if="viewonly" placeholder="Select Serialization" :value="Job.Serialization?.Text" :enabled="false"></ejs-textbox>
                            <ejs-dropdownlist v-else ref="ddlSerialization" name="serialization" placeholder="Select Serialization" v-model:value="Job.SerializationId" :dataSource="serializations.dataSource" :query="serializations.query" :fields="serializations.fields" :allowFiltering="true" filterType="Contains" :enabled="CanEdit" popupWidth="Auto" sortOrder='Ascending'></ejs-dropdownlist>
                            <div id="error-element-serialization"></div>
                        </div>
                    </div>
                </div>
                <div class="row mb-2 gx-2">
                    <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                        <div class="labeled-dropdown">
                            <label>Product Line</label>
                            <ejs-textbox v-if="viewonly" placeholder="Select Product Line" :value="Job.ProductLine?.ProductLine1" :enabled="false"></ejs-textbox>
                            <ejs-dropdownlist v-else ref="ddlProductLine" placeholder="Select Product Line" :enabled="CanEdit" v-model:value="Job.ProductLineId" :dataSource="productLines.dataSource" :query="productLines.query" :fields="productLines.fields" :allowFiltering="true" filterType="Contains" popupWidth="Auto" sortOrder='Ascending'></ejs-dropdownlist>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                        <div class="labeled-dropdown">
                            <label>CPI #</label>
                            <ejs-textbox v-if="viewonly" placeholder="Select CPI" :value="Job.Program?.ProgramName" :enabled="false"></ejs-textbox>
                            <ejs-dropdownlist v-else ref="ddlCpi" placeholder="Select CPI" :enabled="CanEdit" v-model:value="Job.ProgramNumberAndSuffix" :dataSource="programs.dataSource" :query="programs.query" :fields="programs.fields" :allowFiltering="true" filterType="Contains" popupWidth="Auto" :showClearButton="true"></ejs-dropdownlist>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div class="labeled-dropdown">
                            <label>Location</label>
                            <div class="site-btn-group">
                                <ejs-textbox v-if="viewonly" placeholder="Select Location" :value="Job.CurrentLocation?.Name" :enabled="false"></ejs-textbox>
                                <ejs-dropdownlist v-else ref="ddlLocation" placeholder="Select Location" :enabled="CanEditLocation" v-model:value="Job.CurrentLocationId" :dataSource="locations.dataSource" :query="locations.query" :fields="locations.fields" :allowFiltering="true" filterType="Contains" popupWidth="Auto" sortOrder='Ascending'></ejs-dropdownlist>
                                <ejs-button v-on:click="showHistory">History</ejs-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-2 gx-2">
                    <div class="col-12">
                        <div class="labeled-textbox">
                            <label>Comments</label>
                            <ejs-textbox ref="txtComments" placeholder="Type Comments Here" name="comments" :multiline="true" style="max-height: 120px; " v-model:value="Job.Comments" :enabled="CanEdit"></ejs-textbox>
                        </div>
                        <div id="error-element-comments"></div>
                    </div>
                </div>
                <div class="row form-group mb-2 gx-2">
                    <div class="col-4">
                        <div class="e-card ps-2">
                            <ejs-checkbox ref="chkBlueprint" label="Blueprint" v-model:checked="Job.Blueprint" :disabled="!CanEdit"></ejs-checkbox>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="e-card ps-2">
                            <ejs-checkbox ref="chkCadModel" label="CAD Model" v-model:checked="Job.CadModel" :disabled="!CanEdit"></ejs-checkbox>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="e-card ps-2">
                            <ejs-checkbox ref="chkFutureEngineering" label="Future Engineering" v-model:checked="Job.FutureEngineering" :disabled="!CanEdit"></ejs-checkbox>
                        </div>
                    </div>
                </div>
                <div class="row mb-2 gx-2">
                    <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                        <div v-if="!create && Job.DateCommitted" class="labeled-date form-group">
                            <label>Commit Date</label>
                            <ejs-datepicker ref="dtpCommitDate" v-model:value="Job.DateCommitted" :openOnFocus="true" :enabled="CanEditDates" />
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                        <div class="labeled-date form-group">
                            <label>Date Completed</label>
                            <ejs-datepicker ref="dtpDateCompleted" v-model:value="Job.DateCompleted" :openOnFocus="true" :enabled="CanEditDates" />
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <!--This empty label is here to have the checkbox stay inline with the datepickers, without the label the checkbox card would be slightly higher than the datepickers-->
                        <label style="color:#ffffff"></label>
                        <div class="e-card ps-2">
                            <ejs-checkbox ref="chkOutsideSupplier" label="Outside Supplier" v-model:checked="Job.OutsideSupplier" :disabled="!CanEdit"></ejs-checkbox>
                        </div>
                    </div>
                </div>
                <div class="row mb-2 gx-2">
                    <div class="col-lg-8 col-md-12 mb-lg-0 mb-md-2">
                        <div class="card">
                            <div class="card-header" style="justify-content:left; cursor: pointer;" v-on:click="toggleFileVisibility">
                                <div class="row">
                                    <div class="col d-flex align-items-center">
                                        <label>Attachments ({{Job.JobFiles.length}})</label>
                                    </div>
                                    <div v-if="CanEdit" class="col d-flex align-items-center justify-content-end">
                                        <ejs-button id="btnAddAttachment" cssClass="e-small e-success" v-on:click="showUploader"><i class="fa fa-plus"></i></ejs-button>
                                    </div>
                                </div>
                            </div>
                            <Grid ref="gridAttachments"
                                  id="gridAttachments"
                                  class="noPager"
                                  v-if="gridAttachments.visible"
                                  :dataSource="Job.JobFiles"
                                  :columns="gridAttachments.columns"
                                  :allowPaging="false"
                                  :allowTextWrap='true'
                                  :textWrapSettings='gridAttachments.wrapSettings'
                                  :enableStickyHeader="false"
                                  :allowFiltering="false"
                                  :toolbarOverride="true"
                                  @commandClick="gridAttachments.commandClick"
                                  @dataBound="gridAttachments.dataBound"
                                  @rowDataBound="gridAttachments.rowDataBound" />
                            <ejs-uploader ref="fileUploader"
                                          id="fileUploader"
                                          v-show=false
                                          :asyncSettings="fileUploader.asyncSettings"
                                          :dropArea="fileUploader.dropArea"
                                          @uploading="fileUploader.uploading"
                                          @actionComplete="fileUploader.actionComplete"
                                          @success="fileUploader.success"
                                          @failure="fileUploader.failure" />
                        </div>
                    </div>
                    <div v-if="ShowRequiredFileTypes" class="col-lg-4 col-md-12">
                        <div class="card">
                            <div class="card-header">
                                Required Attachments
                            </div>
                            <Grid ref="gridRequiredFileTypes"
                                  id="gridRequiredFileTypes"
                                  class="noPager"
                                  :dataSource="requiredFileTypes"
                                  :columns="gridRequiredFileTypes.columns"
                                  :allowPaging="false"
                                  :enableStickyHeader="false"
                                  :allowFiltering="false"
                                  :toolbarOverride="true"
                                  @commandClick="gridRequiredFileTypes.commandClick"
                                  @dataBound="gridRequiredFileTypes.dataBound" />
                        </div>
                    </div>
                </div>
                <div v-if="ShowHours" class="row mb-2">
                    <div class="col">
                        <div class="card">
                            <div class="card-header">
                                Hours ({{HourSum}})
                            </div>
                            <Grid ref="gridJobHours"
                                  id="gridJobHours"
                                  :columns="gridJobHours.columns"
                                  :dataSource="Job.Hour"
                                  :aggregates="gridJobHours.aggregates"
                                  :toolbar="gridJobHours.toolbar"
                                  :enablePersistence="false"
                                  @dataBound="gridJobHours.dataBound"
                                  @rowDataBound="gridJobHours.rowDataBound"
                                  @toolbarClick="gridJobHours.toolbarClick"
                                  @commandClick="gridJobHours.commandClick"
                                  :editSettings="gridJobHours.editSettings" />
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <LocationHistoryDialog ref="dialogLocationHistory" :modelShopTrackerLocations="Job.ModelShopTrackerLocation"></LocationHistoryDialog>
                <AddRequestor ref="dialogAddRequestor" v-if="CanEdit" @requestorAdded="requestorAdded" />
                <AddHours ref="dialogAddHours" :modelShopTrackerId="Job.Id" :jobNumber="Job.JobNumber" @added="hoursAdded" />
                <SaveJob ref="refSaveConfirmation" :jobNumber="Job.JobNumber" @confirmed="saveConfirmed" @Canceled="saveCanceled"></SaveJob>
            </div>
        </div>
    </div>

    <div v-if="ShowSave" class="float-end" style="position: sticky; bottom: 0px; right: 17px;">
        <ejs-button v-on:click="save" ref="btnSave" :disabled="saving" cssClass="e-info">Save</ejs-button>
    </div>
</template>

<script>
    import Toast from "../../modules/Toast.js";
    import { Query, Predicate } from "@syncfusion/ej2-data";
    import Grid from "../shared/Grid.vue";
    import GridToolbarIds from "../../constants/GridToolbarIds";
    import { DropDownListComponent, MultiSelectComponent } from "@syncfusion/ej2-vue-dropdowns";
    import { DatePickerComponent } from "@syncfusion/ej2-vue-calendars";
    import { TextBoxComponent, NumericTextBoxComponent, UploaderComponent, FormValidator } from "@syncfusion/ej2-vue-inputs";
    import { ButtonComponent, CheckBoxComponent } from "@syncfusion/ej2-vue-buttons";
    import { saveAs } from 'file-saver';
    import LocationHistoryDialog from "./LocationHistoryDialog.vue";
    import Spinner from '../shared/Spinner.vue';
    import { DialogUtility } from '@syncfusion/ej2-vue-popups';
    import AddRequestor from './AddRequestor.vue';
    import DownloadLinkTemplate from "../templates/DownloadLinkTemplate.vue";
    import { createApp } from "vue";
    import RequiredField from '../shared/RequiredField.vue';
    import AddHours from './AddHours.vue';
    import UserTemplate from '../templates/UserTemplate.vue';
    import SaveJob from './SaveJob.vue';
    import { acquireTokenSilent } from '../../modules/AuthConfig.js';

    const app = createApp();

    var cDownloadLinkTemplate = app.component('DownloadLinkTemplate', {
        data: () => ({}),
        template: "<DownloadLinkTemplate :FileName='data.File.OriginalFileName' :JobFileId='data.Id'></DownloadLinkTemplate>",
        components: { "DownloadLinkTemplate": DownloadLinkTemplate }
    });

    let DialogObj = undefined;

    export default {
        name: 'job-form',
        inject: ['router', 'state', 'config', 'ApiClient'],
        components: {
            'ejs-dropdownlist': DropDownListComponent,
            'ejs-multiselect': MultiSelectComponent,
            'ejs-datepicker': DatePickerComponent,
            'ejs-textbox': TextBoxComponent,
            'ejs-numerictextbox': NumericTextBoxComponent,
            'ejs-button': ButtonComponent,
            'ejs-checkbox': CheckBoxComponent,
            'ejs-uploader': UploaderComponent,
            'Grid': Grid,
            LocationHistoryDialog,
            Spinner,
            AddRequestor,
            RequiredField,
            AddHours,
            UserTemplate,
            SaveJob
        },
        props: {
            viewonly: Boolean(true),
            create: Boolean(false),
            prototype: Boolean(false)
        },
        computed: {
            Header() {
                if (this.create) {
                    if (this.Job.Prototype) {
                        return "Add Prototype Job";
                    }
                    else {
                        return "Add Job"
                    }
                }
                else if (!this.viewonly) {
                    if (this.Job.Prototype) {
                        return "Edit Prototype Job";
                    }
                    else {
                        return "Edit Job"
                    }
                }
                else {
                    if (this.Job.Prototype) {
                        return "View Prototype Job";
                    }
                    else {
                        return "View Job"
                    }
                }
            },
            SiteUser() {
                return this.state.data.SiteUser;
            },
            ModelShop() {
                return this.Job;
            },
            CanEdit() {
                return this.Job.Editable && !this.viewonly;
            },
            CanEditLocation() {
                return this.Job.CanEditLocation && !this.viewonly;
            },
            CanEditDates() {
                return this.CanEdit && this.Job.CanEditDates;
            },
            ShowHours() {
                return !(this.$route.params.jobNumber == undefined || this.$route.params.jobNumber <= 0);
            },
            ShowRequiredFileTypes() {
                return this.Job.Prototype && this.requiredFileTypes.length
            },
            CmmContactsEnabled() {
                return (this.Job.WorkType?.AllowCmmUsers ?? false) && this.CanEdit
            },
            CmmContactsDisplay() {
                return this.Job.CmmContactUsers.map(x => x.FullName).join(', ');
            },
            HourSum() {
                return this.Job.Hour.map(x => x.ActualHours).reduce((sum, x) => sum + x, 0).toFixed(2);
            },
            MinDateDue() {
                return (this.state.data.SiteUser.Abilities?.CanCoordinate == true) ? null : this.stripTimeFromDate(this.Job.MinDateDue);
            },
            ShowSave() {
                return (this.create || this.Job && (!this.Job.IsArchive && !this.viewonly) && (this.Job.Editable == true || this.Job.CanEditLocation == true)) && !this.loading;
            },

            cmmContacts() {
                return {
                    dataSource: this.ApiClient.DataSource("Listing/CmmContacts"),
                    fields: {
                        text: 'FullName',
                        value: 'Netid'
                    },
                    query: new Query().take(50),
                    filtering: (e) => {
                        let ds = this.ApiClient.DataSource("Listing/CmmContacts");
                        let query = new Query().take(50);
                        let pred = new Predicate(true);

                        if (e.text != null && e.text != '') {
                            pred = new Predicate('FullName', 'contains', e.text, true);
                            pred = pred.or('NetidDisplay', 'contains', e.text, true);
                            pred = pred.or('Email', 'contains', e.text, true);
                        }

                        query = query.where(pred);

                        e.updateData(ds, query);
                    }
                };
            },
            workTypes() {
                return {
                    dataSource: this.state.data.Dropdowns.WorkTypes,
                    query: this.state.predicateBuilder(this.Job?.WorkTypeId),
                    fields: { text: "Type", value: "Id" },
                    change: (args) => {
                        if (this.$refs.ddlWorkType) {
                            this.Job.WorkType = args.itemData;
                            if (!args.itemData.AllowCmmUsers) {
                                this.Job.CmmContacts = [];
                            }
                            if (args.value != null) {
                                this.ApiClient.Get("JobLockout/StartDate/" + args.itemData.Id, (response) => {
                                    this.Job.MinDateDue = this.stripTimeFromDate(response);
                                });
                            }
                        }
                        if (this.Job.Prototype) {
                            this.ApiClient.Get("RequiredFileType/WorkType/" + args.itemData.Id, (response) => {
                                this.requiredFileTypes = response;
                            });
                        }
                    }
                };
            },
            productLines() {
                return {
                    dataSource: this.state.data.Dropdowns.ProductLines,
                    query: this.state.predicateBuilder(this.Job?.ProductLineId),
                    fields: { text: "ProductLine1", value: "Id" },
                };
            },
            serializations() {
                return {
                    dataSource: this.state.data.Dropdowns.Serializations,
                    query: this.state.predicateBuilder(this.Job?.SerializationId),
                    fields: { text: "Text", value: "Id" },
                };
            },
            inspections() {
                return {
                    dataSource: this.state.data.Dropdowns.Inspections,
                    query: this.state.predicateBuilder(this.Job?.InspectionId),
                    fields: { text: "Text", value: "Id" },
                };
            },
            programs() {
                return {
                    dataSource: this.ApiClient.DataSource("Listing/Programs"),
                    query: new Query().addParams('SpecificProgram', this.Job?.ProgramNumberAndSuffix).take(50),
                    fields: { text: "ProgramName", value: "ProgramNumberAndSuffix" },
                };
            },
            locations() {
                return {
                    dataSource: this.state.data.Dropdowns.Locations,
                    query: this.state.predicateBuilder(this.Job?.CurrentLocationId),
                    fields: { text: "Name", value: "Id" },
                };
            },
            requestors() {
                return {
                    dataSource: this.ApiClient.DataSource("Listing/Requestors"),
                    query: new Query().addParams('SpecificId', this.Job?.RequestorId).take(50),
                    fields: { text: "FullName", value: "Id" },
                    filtering: (e) => {
                        let ds = this.ApiClient.DataSource("Listing/Requestors");
                        let query = new Query().addParams('SpecificId', this.Job?.RequestorId).take(50);
                        let pred = new Predicate(true);

                        if (e.text != null && e.text != '') {
                            pred = new Predicate('FullName', 'contains', e.text, true);
                            pred = pred.or('NetidDisplay', 'contains', e.text, true);
                            pred = pred.or('Email', 'contains', e.text, true);
                        }

                        query = query.where(pred);

                        e.updateData(ds, query);
                    }
                };
            },
        },
        data() {
            return {
                token: null,
                Job: {
                    Prototype: false,
                    ModelShopTrackerLocation: [],
                    Files: [],
                    CmmContacts: [],
                    Hour: []
                },
                gridAttachments: {
                    columns: [
                        {
                            field: 'File.OriginalFileName',
                            headerText: 'File',
                            maxWidth: 500,
                            template: function () {
                                return { template: cDownloadLinkTemplate }
                            },
                        },
                        {
                            field: 'User.FullName',
                            headerText: 'Added By'
                        },
                        {
                            field: 'DateAdded',
                            headerText: 'Date',
                            type: 'date',
                            format: 'M/d/yyyy'
                        },
                        {
                            field: 'RequiredFileType.Name',
                            headerText: 'Required Type'
                        },
                        {
                            headerText: '',
                            commands: [
                                { type: "", title: 'Delete File', buttonOption: { id: GridToolbarIds.Delete, iconCss: 'fa fa-trash', cssClass: "d16-command e-danger d16-delete" } },
                            ]
                        }
                    ],
                    visible: false,
                    wrapSettings: {
                        wrapMode: 'Content'
                    },
                    commandClick: (args) => {
                        if (args.commandColumn.buttonOption.id === GridToolbarIds.Delete) {
                            DialogObj = DialogUtility.confirm({
                                title: 'Delete Attachment',
                                content: "Are you sure you want to delete this attachment?",
                                isModal: true,
                                closeOnEscape: true,
                                okButton: {
                                    icon: 'fa fa-trash',
                                     text: GridToolbarIds.Delete,
                                    cssClass: 'e-danger',
                                    click: () => {
                                        if (this.create) {
                                            this.Job.JobFiles = this.Job.JobFiles.filter(x => x.Id != args.rowData.Id);
                                        }
                                        else {
                                            this.ApiClient.Delete('File/Remove/' + args.rowData.Id, () => {
                                                this.Job.JobFiles = this.Job.JobFiles.filter(x => x.Id != args.rowData.Id);
                                            });
                                        }
                                        DialogObj.hide();
                                    }
                                }
                            });
                        }
                    },
                    dataBound: () => {
                        if (!this.CanEdit) {
                            // NOTE: This hides the column with no header text, which is the command column.
                            this.$refs.gridAttachments.$refs.grid.hideColumns(['']);
                        }

                    },
                    rowDataBound: (args) => {
                        if (this.Job.Prototype && this.requiredFileTypes.length && args.data.RequiredFileTypeId) {
                            if (this.Job.JobFiles.filter(x => x.RequiredFileTypeId == args.data.RequiredFileTypeId).length <= 1) {
                                args.row.querySelector('.d16-delete').style.display = 'none';
                            }
                        }
                    }
                },
                gridRequiredFileTypes: {
                    columns: [
                        {
                            field: 'Name',
                            headerText: 'Name'
                        },
                        {
                            headerText: '',
                            commands: [{ type: "", buttonOption: { id: GridToolbarIds.Add, content: "", cssClass: "custom-edit", iconCss: "fa fa-plus" } },]
                        }
                    ],
                    commandClick: (args) => {
                        if (args.commandColumn.buttonOption.id === GridToolbarIds.Add) {
                            this.selectedFileTypeId = args.rowData.Id;
                            document.getElementsByClassName('e-file-select-wrap')[0].querySelector('button').click();
                        }
                    },
                    dataBound: () => {
                        if (!this.CanEdit) {
                            // NOTE: This hides the column with no header text, which is the command column.
                            this.$refs.gridRequiredFileTypes.$refs.grid.hideColumns(['']);
                        }

                    }
                },
                gridJobHours: {
                    columns: [
                        {
                            field: 'Date',
                            headerText: 'Date',
                            type: 'date',
                            format: 'M/d/yyyy'
                        },
                        {
                            field: 'Id',
                            isPrimaryKey: true,
                            visible: false,
                            showInColumnChooser: false,
                        },
                        {
                            field: 'ActualHours',
                            headerText: 'Hours',
                            format: 'N2',
                        },
                        {
                            field: 'User.FullName',
                            headerText: 'Technician',
                        },
                        {
                            headerText: '',
                            commands: [
                                { type: "", title: 'Edit', buttonOption: { id: GridToolbarIds.Edit, iconCss: 'fa fa-pencil', cssClass: "d16-command e-info" } },
                                { type: "", title: 'Delete', buttonOption: { id: GridToolbarIds.Delete, iconCss: 'fa fa-trash', cssClass: "d16-command e-danger" } }
                            ],
                            showInColumnChooser: false
                        }
                    ],
                    aggregates: [
                        {
                            columns: [
                                {
                                    type: 'Sum',
                                    field: 'ActualHours',
                                    format: 'N2'
                                }
                            ]
                        }
                    ],
                    toolbar: [
                        { text: 'Add', tooltipText: 'Add Hours', id: GridToolbarIds.Add, prefixIcon: 'e-add', },
                    ],
                    pageSettings: { pageSize: 5, pageSizes: [5, 10, 15, 20, 25, 'All'], pageCount: 5 },
                    editSettings: { allowAdding: false, allowDeleting: false },
                    dataBound: () => {
                        if (!this.Job.CanAddHours) {
                            this.$refs.gridJobHours.$refs.grid.ej2Instances.toolbarModule.element.querySelector('#Add').closest('.e-toolbar-item').style.display = 'none';
                        }

                    },
                    rowDataBound: (args) => {
                        if (!args.data.CanRemove || this.viewonly || this.Job.IsArchive) {
                            args.row.querySelector(`[title="${GridToolbarIds.Edit}"]`).style.display = 'none';
                            args.row.querySelector(`[title="${GridToolbarIds.Delete}"]`).style.display = 'none';
                        }
                    },
                    toolbarClick: (args) => {
                        if (args.item.id === GridToolbarIds.Add && this.Job.CanAddHours) {
                            this.$refs.dialogAddHours.show();
                        }
                    },
                    commandClick: (args) => {
                        if (this.viewonly || this.Job.IsArchive || !args.rowData.CanRemove) {
                            args.cancel = true;
                            return;
                        }
                        else if (args.commandColumn.buttonOption.id == GridToolbarIds.Edit) {
                            this.$refs.dialogAddHours.show(args.rowData);
                        }
                        else if (args.commandColumn.buttonOption.id == GridToolbarIds.Delete) {
                            DialogObj = DialogUtility.confirm({
                                title: 'Delete Hours',
                                content: "Are you sure you want to remove these hours for " + args.rowData.User.FullName + "?",
                                isModal: true,
                                closeOnEscape: true,
                                okButton: {
                                    icon: 'fa fa-trash',
                                     text: GridToolbarIds.Delete,
                                    cssClass: 'e-danger',
                                    click: () => {
                                        this.ApiClient.Delete('Hour/' + args.rowData.Id, () => {
                                            this.Job.Hour = this.Job.Hour.filter(x => x.Id != args.rowData.Id);
                                        });
                                        DialogObj.hide();
                                    }
                                }
                            });
                        }
                    },
                },
                fileUploader: {
                    asyncSettings: {
                        saveUrl: this.config.Provider("api_endpoint") + "File/Upload/" + (this.$route.params.jobNumber ?? 'New')
                    },
                    dropArea: '#dropAttachments',
                    uploading: (event) => {
                        if (this.selectedFileTypeId) {
                            event.customFormData = [
                                { RequiredFileTypeId: this.selectedFileTypeId }
                            ];
                        }

                        event.currentRequest.setRequestHeader("Authorization", "Bearer " + this.token);
                    },
                    actionComplete: () => {
                        this.selectedFileTypeId = null;
                    },
                    success: (args) => {
                        if (args.operation == 'upload') {
                            var newJobFile = JSON.parse(args.e.target.response);
                            this.Job.JobFiles.push(newJobFile);
                            Toast.Success(args.statusText);
                        }
                        this.selectedFileTypeId = null;
                    },
                    failure: () => {
                        this.selectedFileTypeId = null;
                    },
                },
                validation: {
                    formObj: {},
                    options: {
                        rules: {
                            partNumber: { required: [true, "Part Number is required"], maxLength: [50, "Part Number must contain 50 or fewer characters"] },
                            partDescription: { maxLength: [250, "Part description limited to 250 characters"] },
                            quantity: { required: [true, "Quantity is required"], range: [[0, 1000], "Quantity must be a number between 0 and 1000"] },
                            chargeNumber: { required: [true, "Charge # is required"], maxLength: [50, "Charge # must contain 50 or fewer characters"] },
                            contactNumber: { required: [true, "Contact Number is required"], maxLength: [50, "Contact Number must contain 50 or fewer characters"] },
                            comments: { maxLength: [500] },
                            workType: { required: [true, "Work Type is required"] },
                            requestor: { required: [true, "Requestor is required"] },
                            inspection: { required: [true, "Inspection is required"] },
                            serialization: { required: [true, "Serialization is required"] },
                            dateDue: { required: [true, "Due Date is required"], date: [this.customRule, "Due Date must be a valid date 4 weeks or more away"] },
                            revision: { required: [true, "Revision is required"], maxLength: [50, "Revision must contain 50 or fewer characters"] },
                        },
                        customPlacement: function (element, errorElement) {
                            var errorContainer = document.querySelector("#error-element-" + element.name);
                            if (errorContainer != undefined) {
                                errorContainer.appendChild(errorElement);
                            } else {
                                Toast.Error("form validation error for field " + element.name)
                            }

                        },
                    },
                },
                requiredFileTypes: [],
                loading: true,
                saving: true,
                selectedFileTypeId: null
            }

        },
        mounted() {
            acquireTokenSilent().then(result => {
                console.log('token retrieved');
                this.token = result.accessToken;
                this.state.GetDropdowns();
                var jobNumber = this.$route.params.jobNumber
                if (jobNumber != undefined && jobNumber >= 0) {
                    if (!isNaN(jobNumber)) {
                        document.title = `Dept 16 - Job #${jobNumber}`;
                        this.getJobByNumber(jobNumber);
                    } else {
                        Toast.error("Unable to resolve Job Number")
                    }
                } else {
                    document.title = 'Dept 16 - New Job';
                    this.getNewJob();
                }
            });
        },
        methods: {
            getNewJob: function () {
                this.ApiClient.Get('ModelShopTracker/New', (response) => {
                    response.MinDateDue = this.stripTimeFromDate(response.MinDateDue);
                    this.Job = response;
                    this.Job.Prototype = this.prototype ?? false;

                    this.loading = false;
                    setTimeout(() => {
                        this.validation.formObj = new FormValidator("#form-element", this.validation.options);
                        this.saving = false;
                    }, 3000);
                });
            },
            getJobByNumber: function (jobNumber) {
                this.ApiClient.Get('ModelShopTracker/JobNumber/' + jobNumber, (response) => {
                    this.addDateObjects(response.Hour)
                    response.MinDateDue = this.stripTimeFromDate(response.MinDateDue);
                    this.Job = response;

                    if (this.Job.Prototype) {
                        this.workTypes.change({ itemData: { Id: this.Job.WorkTypeId } });
                    }
                    this.loading = false;
                    if (this.ShowSave) {
                        setTimeout(() => {
                            this.validation.formObj = new FormValidator("#form-element", this.validation.options);
                            this.saving = false;
                        }, 3000);
                    }
                });
            },
            showHistory: function () {
                this.$refs.dialogLocationHistory.show();
            },
            addUserClicked() {
                this.$refs.dialogAddRequestor.show()
            },
            save: function () {
                this.saving = true;
                if (!this.validation.formObj.validate()) {
                    Toast.Error("Please ensure all inputs are valid")
                    this.saving = false;
                    return
                }

                //we will either create or update depending on the context that this component is used from
                if (this.create) {
                    this.ApiClient.Post("ModelShopTracker", this.Job, (response) => {
                        this.router.push("/EditModelShop/" + response.JobNumber)
                    });
                } else {
                    this.$refs.refSaveConfirmation.show();
                }

            },
            saveConfirmed: function () {
                this.ApiClient.Put("ModelShopTracker/" + this.Job.JobNumber, this.Job, (response) => {
                    this.$refs.refSaveConfirmation.close();
                    this.loading = true;
                    this.addDateObjects(response.Hour);
                    response.MinDateDue = this.stripTimeFromDate(response.MinDateDue);
                    this.Job = response;
                    this.loading = false;
                });
                this.saving = false;
            },
            saveCanceled: function () {
                this.saving = false;
            },
            addDateObjects: function (dates) {
                dates.forEach(x => {
                    x.Date = new Date(x.Date);
                });
            },
            backToList() {
                if (this.Job.Prototype) {
                    this.router.push("/PrototypeTracker");
                }
                else {
                    this.router.push("/ModelShopTracker");
                }
            },
            cancel() {
                DialogObj = DialogUtility.confirm({
                    title: 'Cancel Job',
                    content: "Are you sure you want to cancel this job?",
                    isModal: true,
                    closeOnEscape: true,
                    okButton: {
                        icon: 'fa fa-times',
                        text: 'Cancel',
                        cssClass: 'e-primary',
                        click: () => {
                            this.ApiClient.Put("ModelShopTracker/" + this.Job.JobNumber + "/Cancel/true", null, (response) => {
                                this.loading = true;
                                this.addDateObjects(response.Hour);
                                response.MinDateDue = this.stripTimeFromDate(response.MinDateDue);
                                this.Job = response;
                                this.loading = false;
                            });
                            DialogObj.hide();
                        }
                    },
                    cancelButton: {
                        text: "Don't Cancel Job"
                    }
                });
            },
            uncancel() {
                DialogObj = DialogUtility.confirm({
                    title: 'Un Cancel Job',
                    content: "Are you sure you want to un cancel this job?",
                    isModal: true,
                    closeOnEscape: true,
                    okButton: {
                        icon: 'fa fa-circle-o',
                        text: 'Un Cancel',
                        cssClass: 'e-primary',
                        click: () => {
                            this.ApiClient.Put("ModelShopTracker/" + this.Job.JobNumber + "/Cancel/false", null, (response) => {
                                this.loading = true;
                                this.addDateObjects(response.Hour);
                                response.MinDateDue = this.stripTimeFromDate(response.MinDateDue);
                                this.Job = response;
                                this.loading = false;
                            });
                            DialogObj.hide();
                        }
                    }
                });
            },
            print() {
                this.router.push("/ModelShopTracker/Print/" + this.Job.JobNumber)
            },
            requestorAdded: function (newRequestor) {
                this.$refs.ddlRequestor.ej2Instances.value = newRequestor.Id;
            },
            stripTimeFromDate(args) {
                return new Date(new Date(args).toDateString())
            },
            customRule(args) {
                return this.MinDateDue == null || this.stripTimeFromDate(args['value']) >= this.stripTimeFromDate(this.MinDateDue);
            },
            toggleFileVisibility(args) {
                if (!args.target.closest('#btnAddAttachment')) {
                    this.gridAttachments.visible = !this.gridAttachments.visible;
                }
            },
            downloadFile: function (jobFileId) {
                this.ApiClient.Download("File/Download/" + jobFileId, null, (response, file) => {
                    saveAs(file);
                });
            },
            showUploader: function () {
                this.selectedFileTypeId = null;
                document.getElementsByClassName('e-file-select-wrap')[0].querySelector('button').click();
            },
            hoursAdded: function (newHours) {
                this.Job.Hour = this.Job.Hour.map(x => x.Id === newHours.Id ? newHours : x);
                if (!this.Job.Hour.map(x => x.Id).includes(newHours.Id)) {
                    this.Job.Hour.unshift(newHours);
                }
            },
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    :deep(.e-card > .e-grid) {
        border-width: 1px 0px 0px !important;
    }

    :deep(.e-upload) {
        display: none !important;
    }

    #btnAddAttachment {
        margin: -1px 0px -3px;
    }
</style>
