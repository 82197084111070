<template>
    <div class="card">
        <div class="card-header">
            Users
        </div>
        <Grid ref="gridUserManage"
              id="gridUserManage"
              :toolbar="grid.toolbar"
              :dataSource="grid.dataSource"
              :query="grid.query"
              :columns="grid.columns"
              :height="grid.height"
              :showColumnChooser="true"
              :enablePersistence="true"
              :allowReordering="true"
              @commandClick="grid.commandClick"
              @toolbarClick="grid.toolbarClick" />
    </div>
    <UserForm ref="refUserForm" @saved="refresh" />
</template>

<script>
    import GridHeights from "../../../constants/GridHeights";
    import Grid from "../../shared/Grid.vue";
    import GridToolbarIds from "../../../constants/GridToolbarIds";
    import UserForm from './UserForm.vue';
    import { DialogUtility } from '@syncfusion/ej2-vue-popups';
    import { Query } from "@syncfusion/ej2-data";

    let DialogObj = undefined;

    export default {
        name: 'UserListing',
        inject: ['ApiClient'],
        components: {
            Grid,
            UserForm
        },
        data() {
            return {
                grid: {
                    dataSource: this.ApiClient.DataSource("Listing/Users"),
                    query: new Query().addParams('Active', true),
                    columns: [
                        {
                            textAlign: 'Center',
                            template: '<span class= "e-avatar">${if(Avatar != "" && Avatar != null)}<img src="data:image/jpeg;base64,${Avatar}" />${else}<img src="/img/profile.png" />${/if}</span>',
                            showInColumnChooser: false,
                            width: 100
                        },
                        {
                            field: 'FirstName',
                            headerText: 'First Name',
                        },
                        {
                            field: 'LastName',
                            headerText: 'Last Name',
                        },
                        {
                            field: 'NetidDisplay',
                            headerText: 'Net ID',
                        },
                        {
                            field: 'Email',
                            headerText: 'Email',
                        },
                        {
                            field: 'RoleName',
                            headerText: 'Role',
                        },
                        {
                            field: 'LocationName',
                            headerText: 'Location',
                            type: 'string'
                        },
                        {
                            field: 'DateOktaTileAdded',
                            headerText: 'Date Tile Added',
                            type: 'date',
                            format: 'dd MMM, yyyy hh:mm a'
                        },
                        {
                            field: 'PasswordExpirationDate',
                            headerText: 'Password Expiration Date',
                            type: 'date',
                            format: 'dd MMM, yyyy',
                            visible: false
                        },
                        {
                            field: 'LastLoginDate',
                            headerText: 'Last Login Date',
                            type: 'date',
                            format: 'dd MMM, yyyy hh:mm a',
                            visible: false
                        },
                        {
                            headerText: '',
                            commands: [
                                { type: "", title: 'Edit User', buttonOption: { id: GridToolbarIds.Edit, iconCss: 'fa fa-pencil', cssClass: "d16-command e-info" } },
                                { type: "", title: 'Delete User', buttonOption: { id: GridToolbarIds.Delete, iconCss: 'fa fa-trash', cssClass: "d16-command e-danger" } }
                            ],
                            showInColumnChooser: false,
                            maxWidth: 100
                        },
                    ],
                    toolbar: [
                        { text: 'Add', tooltipText: 'Add User', id: GridToolbarIds.Add, prefixIcon: 'e-add' },
                        { text: "Add App Tile", tooltipText: "Add App Tile", prefixIcon: "e-circle-add", id: GridToolbarIds.AddOktaTile, },
                        { text: "Remove App Tile", tooltipText: "Remove App Tile", prefixIcon: "e-circle-remove", id: GridToolbarIds.RemoveOktaTile, },
                    ],
                    height: GridHeights.base,
                    toolbarClick: (args) => {
                        if (args.item.id === GridToolbarIds.Add) {
                            this.$refs.refUserForm.show();
                        }

                        if (args.item.id === GridToolbarIds.AddOktaTile) {
                            this.setOktaTile(true);
                        }

                        if (args.item.id === GridToolbarIds.RemoveOktaTile) {
                            this.setOktaTile(false);
                        }
                    },
                    commandClick: (args) => {
                        if (args.commandColumn.buttonOption.id === GridToolbarIds.Edit) {
                            let userModel = structuredClone(args.rowData);
                            this.$refs.refUserForm.show(userModel);
                        }
                        if (args.commandColumn.buttonOption.id === GridToolbarIds.Delete) {

                            DialogObj = DialogUtility.confirm({
                                title: 'Delete User',
                                content: 'Confirm you would like to delete user ' + args.rowData.FullName + '.',
                                isModal: true,
                                closeOnEscape: true,
                                okButton: {
                                    icon: 'e-icons e-trash',
                                    text: GridToolbarIds.Delete,
                                    cssClass: 'e-danger',
                                    click: () => {
                                        this.ApiClient.Delete("User/" + args.rowData.Id, () => {
                                            DialogObj.hide();
                                            this.$refs.gridUserManage.refresh();
                                        });
                                    }
                                }
                            });
                        }
                    },
                },
            }
        },
        mounted() {
            document.title = `Dept 16 - Users`;
        },
        methods: {
            refresh: function () {
                this.$refs.gridUserManage.refresh();
            },
            setOktaTile: function (add) {
                let selectedRecord = this.$refs.gridUserManage.$refs.grid.ej2Instances.getSelectedRecords().at(0);
                let path = `User/Okta/${selectedRecord.GlobalUserName}`;
                if (selectedRecord) {
                    if (add === true) {
                        this.ApiClient.Put(path, selectedRecord, (response) => {
                            if (response != null) {
                                this.refresh()
                            }
                        });
                    }
                    else {
                        this.ApiClient.Delete(path, (response) => {
                            if (response != null) {
                                this.refresh()
                            }
                        });
                    }
                }
            },
        },

    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
