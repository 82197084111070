<template>
    <nav id="d16navbar" v-if="!ForPrint" class="navbar navbar-expand-lg py-0">
        <ul class="navbar-nav mx-3">
            <li class='nav-item' id='hamburger' v-on:click="toggleClick" ref="sidbarToggle"><i class="fa fa-bars"></i></li>
        </ul>
        <a ref="HomeAnchor" class="navbar-brand" href="#">
            <img class="d-none d-lg-inline" src="@/assets/img/Dept16-nav-logo-50px-01.png" />
            <img class="d-lg-none" src="@/assets/img/Dept16-logo-50px-02.png" />
        </a>
    </nav>
    <div class="control-section" id="control-content">
        <!--Sidebar element declaration-->
        <ejs-sidebar id='sidebar' v-if="!ForPrint" style="margin-top: 60px;" :enableDock='true' :enableGestures="false" type="Push" :width='width' :dockSize='dockSize' :target='target' ref="sidebarInstance" :isOpen="sidebarOpen">
            <!-- User Conatiner -->
            <div class="container">
                <div class="row">
                    <div v-if="sidebarOpen" style="border-bottom: 2px solid #ddd; padding: 3px;">
                        <div class="col">
                            <div style="display: flex;">
                                <img :src="avatar" v-on:click="reloadUser" class="img-thumbnail avatar bg-light-green" alt="user profile image">
                                <div class="mx-3">
                                    <h4 class="name">
                                        {{userNameDisplay}}
                                    </h4>
                                    <h6 class="text-muted extra">{{netidDisplay}}</h6>
                                </div>
                                <div v-if="SiteUser" class="d-flex" style="font-size: 24px; cursor: pointer;" v-on:click="signOut" title="Sign Out"><i class="fa fa-sign-out"></i></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class='main-menu' style="padding: 0px; margin: 0px;">
                        <div class="list-group list-group-flush" v-if="SiteUser">
                            <RouterLink class="sidenav-item" active-class="active" :to="{ name: 'HomePage' }" title="Home" exact>
                                <i class="fa fa-home"></i>
                                <span v-if="sidebarOpen">Home</span>
                            </RouterLink>

                            <RouterLink v-if="validUser" class="sidenav-item" active-class="active" :to="{ name: 'ModelShopTracker' }" title="Model Shop Tracker" exact>
                                <i class="fa fa-map-marker"></i>
                                <span v-if="sidebarOpen">Model Shop Tracker</span>
                            </RouterLink>

                            <RouterLink v-if="validUser" class="sidenav-item" active-class="active" :to="{ name: 'PrototypeTracker' }" title="Prototype Tracker" exact>
                                <i class="fa fa-map-pin"></i>
                                <span v-if="sidebarOpen">Prototype Tracker</span>
                            </RouterLink>

                            <RouterLink v-if="validUser" class="sidenav-item" active-class="active" :to="{ name: 'HourTracker' }" title="Hours Tracker" exact>
                                <i class="fa fa-clock-o"></i>
                                <span v-if="sidebarOpen">Hours Tracker</span>
                            </RouterLink>

                            <RouterLink v-if="validUser" class="sidenav-item" active-class="active" :to="{ name: 'ArchiveTracker' }" title="Archive" exact>
                                <i class="fa fa-archive"></i>
                                <span v-if="sidebarOpen">Archive</span>
                            </RouterLink>

                            <div v-if="SiteUser?.Abilities?.CanCoordinate" class="sidenav-item" title="Coordinator Options" data-bs-toggle="collapse" data-bs-target="#coordinatorOptions">
                                <i class="fa fa-desktop"></i>
                                <span v-if="sidebarOpen">Coordinator Options</span>
                            </div>

                            <div v-if="SiteUser?.Abilities?.CanCoordinate" id="coordinatorOptions" class="collapse">
                                <RouterLink v-if="SiteUser?.Abilities?.CanCoordinate" class="sidenav-item collapse show" :class="{ nested: sidebarOpen }" active-class="active" :to="{ name: 'Users' }" title="Users" exact>
                                    <i class="fa fa-user"></i>
                                    <span v-if="sidebarOpen">Users</span>
                                </RouterLink>
                                <RouterLink v-if="SiteUser?.Abilities?.CanCoordinate" class="sidenav-item collapse show" :class="{ nested: sidebarOpen }" active-class="active" :to="{ name: 'Dropdowns' }" title="DropDowns" exact>
                                    <i class="fa fa-arrow-down"></i>
                                    <span v-if="sidebarOpen">Drop Downs</span>
                                </RouterLink>
                                <RouterLink v-if="SiteUser?.Abilities?.CanCoordinate" class="sidenav-item collapse show" :class="{ nested: sidebarOpen }" active-class="active" :to="{ name: 'Locations' }" title="Locations" exact>
                                    <i class="fa fa-map-marker"></i>
                                    <span v-if="sidebarOpen">Locations</span>
                                </RouterLink>
                                <RouterLink v-if="SiteUser?.Abilities?.CanCoordinate" class="sidenav-item collapse show" :class="{ nested: sidebarOpen }" active-class="active" :to="{ name: 'Lockouts' }" title="Job Lockouts" exact>
                                    <i class="fa fa-lock"></i>
                                    <span v-if="sidebarOpen">Job Lockouts</span>
                                </RouterLink>
                                <RouterLink v-if="SiteUser?.Abilities?.CanCoordinate" class="sidenav-item collapse show" :class="{ nested: sidebarOpen }" active-class="active" :to="{ name: 'RequiredAttachments' }" title="Required Attachments" exact>
                                    <i class="fa fa-file"></i>
                                    <span v-if="sidebarOpen">Required Attachments</span>
                                </RouterLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="nav-item" v-if="sidebarOpen && ShowMasquerade" ref="masqueradeArea">
                <div class="noRadius" style="display:flex;">
                    <ejs-dropdown ref="SignInAsDdl" 
                                  placeholder="Select a user" 
                                  :showClearButton="true" 
                                  :allowFiltering="true" 
                                  filterType="Contains"
                                  :dataSource='masquerade.dataSource' 
                                  :query="masquerade.query" 
                                  :fields='masquerade.fields' 
                                  @change="masquerade.select" 
                                  popupWidth="Auto" 
                                  :filtering="masquerade.filtering"
                                  :itemTemplate="'userTemplate'">
                        <template v-slot:userTemplate="{data}">
                            <UserTemplate :userInfo="data" />
                        </template>
                    </ejs-dropdown>
                    <ejs-button v-if="SiteUser?.Actor != null" v-on:click="Demasquerade" title="Return to self"><i class="fa fa-user"></i></ejs-button>
                </div>
            </div>
        </ejs-sidebar>
        <!--End of sidebar declaration-->
        <div id="main-text" class="p-2" :class="{ forPrint: ForPrint }">
            <router-view v-show="validUser" :key="$route.path" />
            <div v-if="!validUser">
                <h4>You are not an active user of Department 16. Please contact a system admin for access.</h4>
            </div>
        </div>
    </div>

    <div id="toaster"></div>
</template>

<script>
    import { Sort, Page, Toolbar, Edit, Filter, Resize, Reorder, ExcelExport, Aggregate, ColumnChooser, CommandColumn } from "@syncfusion/ej2-vue-grids";
    import { CheckBoxSelection } from "@syncfusion/ej2-vue-dropdowns";
    import { ButtonComponent } from '@syncfusion/ej2-vue-buttons';
    import { Query, Predicate } from "@syncfusion/ej2-data";
    import { DropDownListComponent } from "@syncfusion/ej2-vue-dropdowns";
    import { SidebarComponent } from "@syncfusion/ej2-vue-navigations";
    import UserTemplate from './components/templates/UserTemplate.vue';
    import { msalInstance } from "./modules/AuthConfig.js";

    export default {
        name: 'App',
        inject: ['state', 'config', 'ApiClient'],
        provide: {
            grid: [Sort, Page, Toolbar, Edit, Filter, Resize, Reorder, ExcelExport, Aggregate, ColumnChooser, CommandColumn ],
            multiselect: [CheckBoxSelection]
        },
        components: {
            'ejs-button': ButtonComponent,
            'ejs-dropdown': DropDownListComponent,
            'ejs-sidebar': SidebarComponent,
            UserTemplate
        },
        data() {
            return {
                masquerade: {
                    dataSource: this.ApiClient.DataSource("GetSignInAsOptions"),
                    query: new Query().take(50),
                    fields: { text: 'FullName', value: 'Id' },
                    filtering: (e) => {
                        let ds = this.ApiClient.DataSource("GetSignInAsOptions");
                        let query = new Query().take(50);
                        let pred = new Predicate(true);

                        if (e.text != null && e.text != '') {
                            pred = new Predicate('FullName', 'contains', e.text, true);
                            pred = pred.or('NetidDisplay', 'contains', e.text, true);
                            pred = pred.or('Email', 'contains', e.text, true);
                        }

                        query = query.where(pred);

                        e.updateData(ds, query);
                    },
                    select: (args) => {
                        if (!args.isInteracted || !this.ShowMasquerade) {
                            return;
                        }
                        this.ApiClient.Put("Masquerade/" + args.itemData.Id, null, (response) => {
                            this.state.SetUser(response);
                            this.$refs.SignInAsDdl.clear();
                            this.$refs.SignInAsDdl.focusOut();
                        }, () => { });
                    }
                },
                dockSize: '44px',
                width: '240px',
                target: '.content',
                sidebarOpen: true,
                clickCount: 0,
                refreshTimer: null
            };
        },
        computed: {
            SiteUser() {
                return this.state.data.SiteUser;
            },
            userNameDisplay() {
                return this.SiteUser?.FullName ?? "";
            },
            netidDisplay() {
                return this.SiteUser?.NetidDisplay ?? "";
            },
            avatar() {
                if (this.SiteUser?.Avatar) {
                    return "data:image/jpeg;base64," + this.SiteUser?.Avatar;
                }
                return "/img/profile.png";
            },
            ShowMasquerade() {
                return (this.SiteUser?.Acting ?? false) || (this.SiteUser?.Abilities?.Masquerade === true);
            },
            ForPrint() {
                return this.$route.meta.isPrint === true;
            },
            validUser() {
                return this.state.UserIsSet();
            },
        },
        mounted() {
            document.title = 'Dept 16 - Model Shop';

            this.$refs.HomeAnchor.href = this.config.Provider("main_url");

            if (!this.state.UserIsSet()) {
                this.state.RefreshUser();
            }
        },
        methods: {
            toggleClick: function () {
                this.sidebarOpen = !this.sidebarOpen;
            },
            signOut: async function() {
                this.state.SetUser({});
                let logoutRequest = {
                    account: msalInstance.getActiveAccount()
                };
                await msalInstance.logoutRedirect(logoutRequest);
            },
            Demasquerade() {
                if (!this.ShowMasquerade) {
                    return;
                }
                this.ApiClient.Put("Demasquerade", null, (response) => {
                    this.state.SetUser(response);
                }, () => { });
            },
            reloadUser: function () {
                this.clickCount += 1;
                if (this.clickCount >= 5) {
                    this.state.RefreshUser();
                    this.state.GetDropdowns(true);
                    this.clickCount = 0;
                    clearTimeout(this.refreshTimer);
                }
                if (this.refreshTimer == null) {
                    this.refreshTimer = setTimeout(() => {
                        this.clickCount = 0;
                    }, 3000);
                }
            }
        }
    }
</script>

<style>
    #d16navbar {
        background-color: #2280c4;
        color: #ffffff;
        font-size: 18px;
        height: 60px;
    }

    .navbar-brand img{
        max-height: 50px;
    }

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
    }

    body {
        font-family: "Lato", sans-serif;
        background-color: #fafafa;
    }

    .e-card{
        line-height: unset;
    }

    #main {
        transition: margin-left .5s;
        padding: 16px;
    }

    .e-table {
        width: 100% !important;
    }

    .custom-edit {
        background-color: #317ab9 !important;
        color: white;
    }

    .control-section {
        width: 100%;
    }

    /*main-menu-header  styles */
    #sidebar .main-menu .main-menu-header {
        color: #656a70;
        padding: 15px;
        font-size: 14px;
        width: 13em;
        margin: 0;
    }

    /*end of main-menu-header styles */

    /*text input styles */
    #sidebar .main-menu .search-icon {
        text-indent: 10px;
        height: 30px;
        width: 18em;
    }

    /*end of text input styles */

    /* table of content area styles */
    #sidebar .table-content {
        padding: 20px;
        height: 5em;
    }

    /* end of table ofcontent area styles */

    /* content area styles */
    #main-text:not(.forPrint) {
        height: calc(100vh - 60px);
        overflow-y: auto;
        background-color: #fafafa;
    }

    #main-text .sidebar-content .line {
        width: 100%;
        height: 1px;
        border-bottom: 1px dashed #ddd;
        margin: 20px 0;
    }

    #main-text .sidebar-content {
        padding: 15px;
    }

    #main-text .sidebar-heading {
        color: #1c86c8;
        margin: 40px 0;
        padding: 2px;
    }

    #main-text .paragraph-content {
        font-family: 'Poppins', sans-serif;
        padding: 5px;
        font-weight: 300;
        color: grey;
    }

    /* end of content area styles */

    /* end of body and html styles */

    /*#sidebar .main-menu .fa {
        margin-left: 0px
    }*/

    .e-list-icon {
        color: #000 !important
    }
    /*.e-active div:hover {
        background-color: #2280c4 !important
    }
    .e-active li:hover{
        background-color: #2280c4 !important
    }*/

    .e-list-text {
        color: #000 !important
    }


    /* user area styles */

    .bg-light-green {
        border: 4px solid lightgreen;
    }

    .name {
        font-size: 22px;
    }

    .text-muted {
        font-size: 14px;
    }

    .simple-card {
        background-color: #fff;
        width: 100%;
        border: 1px solid;
        border-color: #ddd;
        /*border-radius: 3px;*/
        padding: 5px 5px;
    }

    .panel-title {
        font-size: 16px;
        padding: 8px 8px;
    }

    img {
        max-width: 100%;
        height: auto;
    }
    /* end user area styles */

    /* To specify font size and color */

    .card > .d16-grid {
        border: none;
        border-radius: 0px !important;
    }

        .card > .d16-grid .e-gridpager,
        .card > .d16-grid.noPager .e-gridcontent {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
        }

    .e-grid .e-gridheader.e-sticky{
        top: 200px !important;
        margin-top: 200px !important;
    }

    .card-header + .e-grid.d16-grid > .d16-grid.e-toolbar{
        border-top: none !important;
    }

    .d16-command span{
        font-size: 16px !important;
    }

    .sidenav-item {
        padding: 10px 8px;
        font-size: 14px;
        color: #000 !important;
        border-bottom: 1px solid rgb(221, 221, 255);
        text-decoration: none;
        line-height: 12px;
        cursor: pointer;
        height: 37px;
        display: block;
    }

        .sidenav-item i {
            margin-left: 4px;
            margin-right: 5px;
            width: 20px;
            font-size: 16px;
            text-align: center;
        }

        .sidenav-item.active {
            background-color: rgb(34, 128, 196);
        }

        .sidenav-item:hover {
            background-color: #66afe9;
        }

        .sidenav-item.nested{
            padding-left: 25px;
        }


    .site-btn-group{
        display: flex;
    }

    .site-btn-group > :not(:last-child):first-child,
    .site-btn-group > :not(:last-child):first-child:hover,
    .site-btn-group > :not(:last-child):first-child:active,
    .site-btn-group > :not(:last-child):first-child:focus {
        border-bottom-right-radius: 0px !important;
        border-top-right-radius: 0px !important;
    }
    .site-btn-group > :not(:first-child):last-child,
    .site-btn-group > :not(:first-child):last-child:hover,
    .site-btn-group > :not(:first-child):last-child:active,
    .site-btn-group > :not(:first-child):last-child:focus {
        border-bottom-left-radius: 0px !important;
        border-top-left-radius: 0px !important;
        border-left: 0px !important;
    }
    .site-btn-group > :not(:first-child):not(:last-child),
    .site-btn-group > :not(:first-child):not(:last-child):hover,
    .site-btn-group > :not(:first-child):not(:last-child):active,
    .site-btn-group > :not(:first-child):not(:last-child):focus {
        border-radius: 0px !important;
        border-left: 0px !important;
        border-right: 0px !important;
    }

    .noRadius, .noRadius *{
        border-radius: 0px !important;
    }

    .avatar{
        height: 55px;
        width: auto;
        padding: 0px;
    }

    #hamburger{
        cursor: pointer;
        font-size: 30px;
    }

    .e-dialog .e-dlg-header-content {
        background-color: #2280c4;
        color: #ffffff;
    }

    .e-dialog .e-dlg-header {
        color: #ffffff;
    }

    .e-dlg-container .e-dialog{
        margin: 30px !important;
    }

    .e-dlg-content {
        max-height: calc(100vh - 200px);
    }

    .e-toolbar-item.e-template{
        padding-top: 3px !important;
        padding-bottom: 3px !important;
    }

    .e-multiselect.e-disabled .e-chips-close,
    .e-ddl.e-disabled .e-ddl-icon {
        display: none;
    }



    .statusColor-Green {
        background-color: #dff0d8 !important;
        color: green !important;
    }

    .statusColor-Blue {
        background-color: #CDE4F7 !important;
        color: blue !important;
    }

    .statusColor-Yellow {
        background-color: lightyellow !important;
        color: #F0AD4E !important;
    }

    .statusColor-White {
        color: #000000 !important;
    }

    .statusColor-Orange {
        background-color: #FFD545 !important;
        color: orangered !important;
    }

    .filterFill {
        background-color: #66afe9 !important;
    }

    .e-cc > .e-btn{
        margin-top: -3px !important;
    }

    .userListingItem {
        display: inline-block;
    }

        .userListingItem,
        .userListingItem * {
            line-height: 20px;
        }

    .userListingItemDetails {
        font-size: 11px;
        color: #8d8d8d;
    }

    .mismatch-overlay {
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 1000000;
        height: 100%;
        width: 100%;
        background: white;
        opacity: .9;
        cursor: pointer;
    }

    .mismatch-overlay-inner {
        background: white;
        opacity: 1;
        width: 70%;
        padding: 25px;
        text-align: center;
    }

    [class^="e-"],
    .e-btn,
    .e-css.e-btn,
    .e-input-group,
    .e-ddl,
    .e-input,
    .e-pager .e-pagercontainer,
    .e-pager .e-spacing, .e-pager .e-numericitem:hover, .e-pager .e-currentitem,
    .e-grid,
    .e-treegrid,
    .dropdown-menu,
    .alert,
    .e-dropdown-popup ul {
        border-radius: 0px !important;
    }

    .card, .card-header, .card-body, .card-footer {
        border-radius: 0px !important;
    }

    div.qrcode {
        height: 200px;
        width: 200px;
        padding: 1px;
        float: right;
        pointer-events: none;
        -webkit-print-color-adjust: exact;
    }

    @import "bootstrap/dist/css/bootstrap.css";
    @import "@syncfusion/ej2-base/styles/bootstrap.css";
    @import "@syncfusion/ej2-icons/styles/bootstrap.css";
    @import "@syncfusion/ej2-vue-buttons/styles/bootstrap.css";
    @import "@syncfusion/ej2-vue-inputs/styles/bootstrap.css";
    @import "@syncfusion/ej2-notifications/styles/bootstrap.css";
    @import "@syncfusion/ej2-vue-dropdowns/styles/bootstrap.css";
    @import "@syncfusion/ej2-vue-grids/styles/bootstrap.css";
    @import "@syncfusion/ej2-vue-popups/styles/bootstrap.css";
    @import "@syncfusion/ej2-layouts/styles/bootstrap.css";
    @import "@syncfusion/ej2-vue-navigations/styles/bootstrap.css";
    @import "@syncfusion/ej2-vue-calendars/styles/bootstrap.css";
    @import '@syncfusion/ej2-splitbuttons/styles/bootstrap.css';
    @import '@syncfusion/ej2-lists/styles/bootstrap.css';
    @import '@syncfusion/ej2-vue-pdfviewer/styles/bootstrap.css';
    @import "@syncfusion/ej2-vue-filemanager/styles/bootstrap.css";
</style>
