<template>
    <ejs-button id="backButton" v-on:click="goBack" class="mb-1"><span class="fa fa-arrow-left" /> Go Back</ejs-button>
    <div style="display: flex; justify-content: left;">
        <h1>{{location.Name}}</h1>
    </div>
    <div style="display: flex; justify-content: left;">
        <div v-if="location.Qr" class="qrcode" v-bind:style="{ backgroundImage: 'url(data:image/png;base64,' + location.Qr + ')', backgroundSize: '100% 100%' }"> </div>
    </div>

</template>

<script>
    import { ButtonComponent } from "@syncfusion/ej2-vue-buttons";
    import Toast from "../../modules/Toast.js";

    export default {
        name: 'PrintLocation',
        inject: ['router', 'ApiClient'],
        components: {
            'ejs-button': ButtonComponent
        },
        props: {
            id: String
        },
        data() {
            return {
                location: {},
                text: "",
                qr: ""
            }
        },
        mounted() {
            Toast.Clear();
            this.ApiClient.Get('Location/' + this.$route.params.id, (response) => {
                this.location = response;
                this.text = response.Name
                this.qr = response.Qr
                document.title = `Dept 16 - Location: ${this.location.Name}`;
                setTimeout(() => { window.print(); }, 500)
            });
        },
        unmounted() {
        },
        methods: {
            goBack() {
                this.router.back();
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    h3 {
        margin: 40px 0 0;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        display: inline-block;
        margin: 0 10px;
    }

    a {
        color: #42b983;
    }

    .row {
        margin-bottom: 5px;
    }

    @media print {
        #backButton {
            display: none !important;
        }
    }
</style>
