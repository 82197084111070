<template>
    <ejs-dialog ref="dialog" :beforeOpen="beforeOpen" :position="position" :isModal="true" :visible="false" :width="600" :header="header" :buttons="buttons">
        <div class="timeline-centered">
            <LocationHistoryItem v-for="item in modelShopTrackerLocations" :item="item" :key="item.Id">
            </LocationHistoryItem>
            <div class="timeline-entry begin">
                <div class="timeline-entry-inner">
                    <div class="timeline-icon">
                        <i class="fa fa-flag-o"></i>
                    </div>
                </div>
            </div>
        </div>
    </ejs-dialog>
</template>

<script>
    import { DialogComponent } from "@syncfusion/ej2-vue-popups";
    import LocationHistoryItem from "./LocationHistoryItem";

    export default {
        components: {
            'ejs-dialog': DialogComponent,
            LocationHistoryItem
        },
        props: {
            modelShopTrackerLocations: Array
        },
        mounted() {
        },
        expose: ["show"],
        data() {
            return {
                header: "Location History",
                buttons: [
                    {
                        click: this.close, buttonModel: { content: 'Close' }
                    }
                ],
                position: { X: "center", Y: "top" },
            }
        },
        methods: {
            close() {
                this.$refs.dialog.hide();
            },
            show() {
                this.$refs.dialog.show();
            },
            beforeOpen(args) {
                if (!this.modelShopTrackerLocations || this.modelShopTrackerLocations.length < 1) {
                    args.cancel = true;
                }
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    @import "../../assets/style/style.css";

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        display: inline-block;
        margin: 0 10px;
    }

    a {
        color: #42b983;
    }
</style>
