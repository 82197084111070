import { PublicClientApplication } from '@azure/msal-browser';
import Config from './Config';


// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        authority: "https://login.microsoftonline.com/21942f3d-edf7-4805-9a5b-e03d208249c5",
        clientId: Config.Provider('entra').clientId,
        clientSecret: Config.Provider('entra').clientSecret,
        tenantId: Config.Provider('tenantId'),
        redirectUri: Config.Provider('redirectUri'),
        postLogoutRedirectUri: Config.Provider('postLogoutRedirectUri'),
    },
    cache: {
        cacheLocation: 'localStorage'
    },
};

export const msalInstance = new PublicClientApplication(msalConfig);
await msalInstance.initialize();

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: [`${Config.Provider('entra').clientId}/.default`],
};

export async function acquireTokenSilent() {
    const accounts = msalInstance.getAllAccounts();

    loginRequest.account = accounts[0];

    return msalInstance.acquireTokenSilent(loginRequest);
}