const defaultLib = 'default'; 

const config = {
    default: {
        ej_license: "ORg4AjUWIQA/Gnt2UVhhQlVBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5Sd0ViWX1ecXFXRGFU;Mgo+DSMBMAY9C3t2UVhhQlVBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5Sd0ViWX1ecXFXRWdZ",
        base_url: "/",
        api_endpoint: window.location.origin.toLowerCase() + "/api/",
        main_url: window.location.origin.toLowerCase() + "/",
        redirectUri: window.location.origin.toLowerCase() + "/i/spa-oidc",
        postLogoutRedirectUri: window.location.origin.toLowerCase() + "/Logout",
        tenantId: '21942f3d-edf7-4805-9a5b-e03d208249c5'
    },
    Local: {
        entra: {
            clientId: "2935e7ea-47ee-406e-9655-ed90b35d428d",
            clientSecret: "p4q8Q~elF4Xt8mtBvQJPpF4mHILsPkG6U1jjYbqv",
        },
        api_endpoint: "https://localhost:7288/",
        app_insights_connection: 'InstrumentationKey=19a163b6-c296-4ca9-b3f9-5318293210eb;IngestionEndpoint=https://northcentralus-0.in.applicationinsights.azure.com/;LiveEndpoint=https://northcentralus.livediagnostics.monitor.azure.com/'
    },
    Development: {
        entra: {
            clientId: "2935e7ea-47ee-406e-9655-ed90b35d428d",
            clientSecret: "p4q8Q~elF4Xt8mtBvQJPpF4mHILsPkG6U1jjYbqv",
        },
        app_insights_connection: 'InstrumentationKey=19a163b6-c296-4ca9-b3f9-5318293210eb;IngestionEndpoint=https://northcentralus-0.in.applicationinsights.azure.com/;LiveEndpoint=https://northcentralus.livediagnostics.monitor.azure.com/'
    },
    Test: {
        entra: {
            clientId: "c0adaaf6-bcf8-4a60-a921-9f807e85b96c",
            clientSecret: "L268Q~Wx1-LFwH092L7ux0q3c_fdHytMkt.RPcZp",
        },
        app_insights_connection: 'InstrumentationKey=5be4fc60-2c32-4d06-aee3-84157c9d28e7;IngestionEndpoint=https://northcentralus-0.in.applicationinsights.azure.com/;LiveEndpoint=https://northcentralus.livediagnostics.monitor.azure.com/'
    },
    Production: {
        entra: {
            clientId: "868766a4-b61d-44d2-ab76-acb543a8da2a",
            clientSecret: "oB88Q~Oo2YYgDjcXFs0d9PomsvLPE5oYyyH4.aHS",
        },
        app_insights_connection: 'InstrumentationKey=2247af96-af38-4368-9631-5b73ff324c84;IngestionEndpoint=https://northcentralus-0.in.applicationinsights.azure.com/;LiveEndpoint=https://northcentralus.livediagnostics.monitor.azure.com/'
    },
};

function environment() {
    let origin = window.location.origin.toLowerCase();
    let env = "Local";

    // The order of these is very important.  Do not change.

    if (origin.indexOf("dept16") != -1) {
        env = "Production";
    }

    if (origin.indexOf("dept16dev") != -1) {
        env = "Development";
    }

    if (origin.indexOf("dept16test") != -1) {
        env = "Test";
    }

    return env;
}
export default {
    Provider: (key) => {
        let env = environment();
        let toReturn = Object.hasOwn(config[env], key) ? config[env][key] : config[defaultLib][key];
        return toReturn;
    },
    Environment: environment()
};
