<template>
    <span :title="fieldName + ' is required'">
        <i class="fa fa-star"></i>
    </span>
</template>
<script>
    export default {
        props: {
            fieldName: String
        }
    }
</script>

<style scoped>
    i {
        font-size: 6px;
        color: red;
        vertical-align: super
    }
</style>