import { createRouter, createWebHistory } from "vue-router";
import HomePage from "../../components/HomePage.vue";
import ModelShopTracker from "../../components/ModelShopTracker/ModelShopTracker.vue";
import JobForm from '../../components/ModelShopTracker/JobForm.vue';
import PrototypeTracker from "../../components/ModelShopTracker/PrototypeTracker.vue";
import Archive from "../../components/ModelShopTracker/Archive.vue";
import HourTracker from "../../components/HourTracker.vue";
import UserListing from "../../components/coordinator/user/Users.vue";
import DropDowns from "../../components/coordinator/DropDowns.vue";
import Locations from "../../components/coordinator/Locations.vue";
import Lockouts from "../../components/coordinator/Lockouts.vue";
import RequiredAttachments from "../../components/coordinator/RequiredAttachments.vue";
import PrintLocation from "../../components/coordinator/PrintLocation.vue";
import PrintJob from "../../components/ModelShopTracker/PrintJob.vue";
import Logout from "../../components/account/Logout.vue";
import config from "../Config.js";
import state from "../State.js";
import Toast from "../Toast";
import { registerGuard } from "./Guard";

const router = createRouter({
    history: createWebHistory(config.Provider("base_url")),
    routes: [
        {
            path: '/i/spa-oidc',
            component: HomePage,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/",
            name: "HomePage",
            component: HomePage,
            meta: {
                title: "Home",
                requiresAuth: true
            }
        },
        {
            path: "/ModelShopTracker",
            name: "ModelShopTracker",
            component: ModelShopTracker,
            meta: {
                title: "Model Shop Tracker",
                requiresAuth: true
            }
        },
        {
            path: "/ModelShopTracker/Print/:jobNumber",
            name: "PrintJob",
            component: PrintJob,
            meta: {
                title: "Print Job",
                requiresAuth: true,
                isPrint: true
            }
        },
        {
            path: "/EditModelShop/:jobNumber",
            name: "EditJob",
            component: JobForm,
            meta: {
                title: "Edit Job",
                requiresAuth: true
            },
            props: {
                viewonly: false,
            }
        },
        {
            path: "/ViewModelShop/:jobNumber",
            name: "ViewJob",
            component: JobForm,
            meta: {
                title: "View Job",
                requiresAuth: true
            },
            props: {
                viewonly: true,
            }
        },
        {
            path: "/AddModelShop",
            name: "AddJob",
            component: JobForm,
            meta: {
                title: "Add Job",
                requiresAuth: true
            },
            props: {
                create: true,
                viewonly: false,
                prototype: false
            }
        },
        {
            path: "/AddPrototype",
            name: "AddPrototype",
            component: JobForm,
            meta: {
                title: "Add Prototype Job",
                requiresAuth: true
            },
            props: {
                create: true,
                viewonly: false,
                prototype: true
            }
        },
        {
            path: "/PrototypeTracker",
            name: "PrototypeTracker",
            component: PrototypeTracker,
            meta: {
                title: "Prototype Tracker",
                requiresAuth: true
            }
        },
        {
            path: "/Archive",
            name: "ArchiveTracker",
            component: Archive,
            meta: {
                title: "Archive",
                requiresAuth: true
            }
        },
        {
            path: "/HourTracker",
            name: "HourTracker",
            component: HourTracker,
            meta: {
                title: "Hour Tracker",
                requiresAuth: true
            }
        },
        {
            path: "/Coordinator/Users",
            name: "Users",
            component: UserListing,
            meta: {
                title: "Users",
                requiresAuth: true,
                requiresCoordinator: true
            }
        },
        {
            path: "/Coordinator/Dropdowns",
            name: "Dropdowns",
            component: DropDowns,
            meta: {
                title: "Drop Downs",
                requiresAuth: true,
                requiresCoordinator: true
            }
        },
        {
            path: "/Coordinator/Locations",
            name: "Locations",
            component: Locations,
            meta: {
                title: "Locations",
                requiresAuth: true,
                requiresCoordinator: true
            }
        },
        {
            path: "/Coordinator/Lockouts",
            name: "Lockouts",
            component: Lockouts,
            meta: {
                title: "Lockouts",
                requiresAuth: true,
                requiresCoordinator: true
            }
        },
        {
            path: "/Coordinator/RequiredAttachments",
            name: "RequiredAttachments",
            component: RequiredAttachments,
            meta: {
                title: "Required Attachments",
                requiresAuth: true,
                requiresCoordinator: true
            }
        },
        {
            path: "/Location/Print/:id",
            name: "PrintLocation",
            component: PrintLocation,
            meta: {
                title: "Print",
                requiresAuth: true,
                isPrint: true
            }
        },
        {
            path: "/Logout",
            name: 'Logout',
            component: Logout,
            meta: {
                title: 'Logout',
                requiresAuth: false
            }
        },
    ]
});

registerGuard(router);

router.beforeResolve(to => {
    if (to.meta.requiresCoordinator) {
        if (!state?.data?.SiteUser?.Abilities?.CanCoordinate) {
            Toast.Warning("Not authorized to access this page or directory")
            return false;
        }
    }
});
export default router;