import Toast from "./Toast.js";
import config from "../modules/Config";
import { DataManager, UrlAdaptor, Query } from "@syncfusion/ej2-data";
import { acquireTokenSilent } from './AuthConfig.js';

async function getHeaders() {
    let token = await acquireTokenSilent();
    var headers = new Headers();
    var bearer = "Bearer " + token.accessToken;

    headers.append('Content-Type', 'application/json');
    headers.append("Authorization", bearer);

    return headers;
}

const ApiClient = {
    Download: async (url, data, success, always, error) => {
        var headers = await getHeaders();
        var options = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data)
        };
        let message = null;
        var endpoint = config.Provider("api_endpoint") + url;

        fetch(endpoint, options)
            .then((response) => {
                if (response.ok !== true) {
                    throw response;
                }

                message = response.headers.get("x-api-message");

                return response.json();
            })
            .then((json) => {
                let blob = b64toBlob(json.Bytes, json.ContentType);
                let file = new File([blob], json.OriginalFileName);

                success(json, file, message);

                if (always) {
                    always();
                }
            })
            .catch((e) => {
                if (e.status === 400) {
                    e.text().then((text) => {
                        if (text !== null && text.length > 0) {
                            if (error) {
                                error(text);
                            } else {
                                Toast.Warning(text);
                            }
                        }
                    });
                }

                if (e.status === 500) {
                    Toast.Error("There was an error on the server. Please try again later.");
                }

                if (always) {
                    always();
                }
            });
    },
    Get: async (url, success, always, showMessage = false) => {
        var headers = await getHeaders();
        var options = {
            method: "GET",
            headers: headers
        };
        let message = null;
        var endpoint = config.Provider("api_endpoint") + url;

        fetch(endpoint, options)
            .then((response) => {
                if (response.ok !== true) {
                    throw response;
                }

                message = response.headers.get("x-api-message");

                if (showMessage) {
                    Toast.Success(message);
                }

                return response.json();
            })
            .then((json) => {
                success(json, message);
                if (always) {
                    always();
                }
            })
            .catch((e) => {
                if (e.status === 400) {
                    e.text().then((text) => {
                        if (text !== null && text.length > 0) {
                            Toast.Error(text);
                        }
                    });
                }
                if (e.status === 401) {
                    Toast.Error("User is not Authenticated, Please reload the page or request access.")
                }
                if (e.status === 403) {
                    Toast.Error("Not Authorized to access this rescource.")
                }
                if (e.status === 500) {
                    Toast.Error("There was an error on the server. Please try again later.");
                }

                if (always) {
                    always();
                }
            });
    },
    Put: async (url, data, success, always) => {
        var headers = await getHeaders();
        var options = {
            method: "PUT",
            headers: headers,
            body: JSON.stringify(data),
        };
        var endpoint = config.Provider("api_endpoint") + url;

        fetch(endpoint, options)
            .then((response) => {
                if (response.ok !== true) {
                    throw response;
                }

                let message = response.headers.get("x-api-message");

                Toast.Success(message);

                return response.json();
            })
            .then((json) => {
                success(json);

                if (always) {
                    always();
                }
            })
            .catch((e) => {
                if (e.status === 400) {
                    e.text().then((text) => {
                        Toast.Error(text);
                    });
                }

                if (e.status === 500) {
                    Toast.Error("There was an error on the server. Please try again later.");
                }

                if (always) {
                    always();
                }
            });
    },
    Post: async (url, data, success, always) => {
        var headers = await getHeaders();
        var options = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data),
        };
        var endpoint = config.Provider("api_endpoint") + url;
        let message = "";

        fetch(endpoint, options)
            .then((response) => {
                if (response.ok !== true) {
                    throw response;
                }

                message = response.headers.get("x-api-message");

                Toast.Success(message);

                return response.json();
            })
            .then((json) => {
                success(json, message);

                if (always) {
                    always();
                }
            })
            .catch((e) => {
                if (e.status === 400) {
                    e.text().then((text) => {
                        Toast.Error(text);
                    });
                }

                if (e.status === 500) {
                    Toast.Error("There was an error on the server. Please try again later.");
                }

                if (always) {
                    always();
                }
            });
    },
    Delete: async (url, success, always) => {
        var headers = await getHeaders();
        var options = {
            method: "DELETE",
            headers: headers,
        };
        var endpoint = config.Provider("api_endpoint") + url;

        fetch(endpoint, options)
            .then((response) => {
                if (response.ok !== true) {
                    throw response;
                }

                let message = response.headers.get("x-api-message");

                Toast.Success(message);

                return response.json();
            })
            .then((json) => {
                success(json);

                if (always) {
                    always();
                }
            })
            .catch((e) => {
                if (e.status === 400) {
                    e.text().then((text) => {
                        Toast.Error(text);
                    });
                }

                if (e.status === 500) {
                    Toast.Error("There was an error on the server. Please try again later.");
                }

                if (always) {
                    always();
                }
            });
    },
    DataSource: (url, offline = false, success = null) => {
        let toReturn = new MyDataManager({
            url: config.Provider("api_endpoint") + url,
            adaptor: new CustomAdaptor(),
            offline: offline,
        });

        if (success != null) {
            toReturn = toReturn.executeQuery(new Query()).then((x) => success(x));
        }

        return toReturn;
    }
}

const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}

export default ApiClient;

class CustomAdaptor extends UrlAdaptor {
    _authToken;
    constructor() {
        super();
    }
    async updateAuthToken() {
        this._authToken = await acquireTokenSilent();
    }

    beforeSend(dm, request) {
        if (this._authToken?.accessToken) {
            request.headers.set('Authorization', 'Bearer ' + this._authToken.accessToken);
        }
    }
}

class MyDataManager extends DataManager {
    constructor(datasource, query, adaptor) {
        super(datasource, query, adaptor);
    }

    async executeQuery(query, done, fail, always) {
        await this.adaptor.updateAuthToken();
        return super.executeQuery(query, done, fail, always);
    }
}
