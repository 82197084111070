<template>
    <div class="card">
        <div class="card-header">
            Archive
        </div>
        <Grid ref="gridArchive"
              id="gridArchive"
              :dataSource="grid.dataSource"
              :query="grid.query"
              :columns="grid.columns"
              :height="grid.height"
              :showColumnChooser="true"
              :enablePersistence="true"
              :allowReordering="true"
              @commandClick="grid.commandClick"
              @recordDoubleClick="grid.recordDoubleClick" />
    </div>
</template>

<script>
    import Grid from "../shared/Grid.vue";
    import GridToolbarIds from "../../constants/GridToolbarIds";
    import GridHeights from "../../constants/GridHeights";
    import { Query } from "@syncfusion/ej2-data";

    export default {
        name: 'ArchiveTracker',
        inject: ['router', 'state', 'ApiClient'],
        components: {
            Grid,
        },
        data() {
            return {
                grid: {
                    dataSource: this.ApiClient.DataSource("ModelShopTracker/DataSource"),
                    query: new Query().addParams('archive', true),
                    columns: [
                        {
                            field: 'JobNumber',
                            headerText: 'Job Number',
                        },
                        {
                            field: 'StatusDisplay',
                            headerText: 'Status',
                        },
                        {
                            field: 'WorkType',
                            headerText: 'Work Type',
                        },
                        {
                            field: 'LocationName',
                            headerText: 'Location',
                        },
                        {
                            field: 'Requestor',
                            headerText: 'Requestor',
                        },
                        {
                            field: 'PartNumber',
                            headerText: 'Part Number',
                        },
                        {
                            field: 'TechnicianDisplay',
                            headerText: 'Technicians',
                            allowFiltering: false,
                            allowSorting: false,
                            maxWidth: 300
                        },
                        {
                            field: 'PartDescription',
                            headerText: 'Part Description',
                        },
                        {
                            headerText: '',
                            commands: [
                                { type: "", title: 'View Job', buttonOption: { id: GridToolbarIds.View, iconCss: 'fa fa-file', cssClass: "d16-command e-flat" } },
                            ],
                            showInColumnChooser: false
                        }
                    ],
                    height: GridHeights.base,
                    commandClick: (args) => {
                        if (args.commandColumn.buttonOption.id == GridToolbarIds.View) {
                            this.router.push("/ViewModelShop/" + args.rowData.JobNumber)
                        }
                    },
                    recordDoubleClick: (args) => {
                        this.router.push("/ViewModelShop/" + args.rowData.JobNumber);
                    }
                },
            }
        },
        mounted() {
            document.title = 'Dept 16 - Archive';
        },
        computed: {
            SiteUser() {
                return this.state.data.SiteUser;
            },
        },
        methods: {
            load: function () {
            },
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        display: inline-block;
        margin: 0 10px;
    }

    a {
        color: #42b983;
    }
</style>
