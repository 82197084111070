<template>
    <div>
        <router-link class="grid-link" :to='{path: "EditModelShop/" + jobNumber }'>{{link}}</router-link>
    </div>
</template>

<script>

    export default {
        components: {},
        mounted() {
            this.jobNumber = this.link
        },
        data() {
            return {
                jobNumber: 0
            }
        },
        methods: {
            
        },
        props: {
            link: Number,
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    h3 {
        margin: 40px 0 0;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        display: inline-block;
        margin: 0 10px;
    }

    a {
        color: #42b983;
    }
    .grid-link{
        color: gray;
        text-decoration: none;
    }
</style>
