<template>
    <div class="row g-2">
        <div class=" col-md-12 col-lg-6">
            <div class="card" id="inspection-form">
                <div class="card-header">
                    Inspections
                </div>
                <Grid ref="gridInspectionsManage"
                      id="gridInspectionsManage"
                      class="noPager"
                      :dataSource="inspectionsGrid.dataSource"
                      :columns="inspectionsGrid.columns"
                      :toolbar="inspectionsGrid.toolbar"
                      :editSettings="inspectionsGrid.editSettings"
                      :allowPaging="false"
                      :enablePersistence="false"
                      @actionBegin="inspectionsGrid.actionBegin"
                      @toolbarClick="inspectionsGrid.toolbarClick" />
            </div>
        </div>
        <div class=" col-md-12 col-lg-6">
            <div class="card" id="serialization-form">
                <div class="card-header">
                    Serializations
                </div>
                <Grid ref="gridSerializationsManage"
                      id="gridSerializationsManage"
                      class="noPager"
                      :dataSource="serializationsGrid.dataSource"
                      :columns="serializationsGrid.columns"
                      :toolbar="serializationsGrid.toolbar"
                      :editSettings="serializationsGrid.editSettings"
                      :allowPaging="false"
                      :enablePersistence="false"
                      @actionBegin="serializationsGrid.actionBegin"
                      @toolbarClick="serializationsGrid.toolbarClick" />
            </div>
        </div>
    </div>
</template>

<script>
    import Grid from "../shared/Grid.vue";
    import GridToolbarIds from "../../constants/GridToolbarIds";
    import { DialogUtility } from '@syncfusion/ej2-vue-popups';

    let DialogObj = undefined;

    export default {
        name: 'dropdowns-component',
        inject: ['ApiClient', 'state'],
        components: {
            Grid
        },
        props: {

        },
        data() {
            return {
                inspectionsGrid: {
                    dataSource: this.ApiClient.DataSource("Inspection/DataSource"),
                    columns:[
                        {
                            field: 'Text',
                            headerText: 'Value',
                            type: 'string',
                            validationRules: { 
                                required: [true, "Inspection name is required"], 
                                maxLength: [250, "Inspection name must contain 250 or fewer characters"]
                            }
                        },
                        {
                            field: 'Id',
                            isPrimaryKey: true,
                            visible: false
                        },
                        {
                            headerText: '',
                            commands: [
                                { type: 'Edit', title: 'Edit Inspection', buttonOption: { id: GridToolbarIds.Edit, iconCss: 'fa fa-pencil', cssClass: "d16-command e-info" } },
                                { type: 'Delete', title: 'Delete Inspection', buttonOption: { id: GridToolbarIds.Delete, iconCss: 'fa fa-trash', cssClass: "d16-command e-danger" } },
                                { type: 'Save', title: 'Delete Inspection', buttonOption: { id: GridToolbarIds.Save, iconCss: 'fa fa-save', cssClass: "d16-command e-success" } },
                                { type: 'Cancel', title: 'Cancel', buttonOption: { id: GridToolbarIds.Cancel, iconCss: 'fa fa-times', cssClass: "d16-command e-flat" } }
                            ],
                            maxWidth: 100
                        }
                    ],
                    toolbar: [
                        { text: 'Add', tooltipText: 'Add Inspection', id: GridToolbarIds.Add, prefixIcon: 'e-add' }
                    ],
                    editSettings: {
                        allowAdding: true,
                        allowEditing: true,
                        allowDeleting: true
                    },
                    actionBegin: (args) => {
                        if (args.requestType == 'save') {
                            args.cancel = true;
                            if (args.data.Text == args.previousData.Text) {
                                this.$refs.gridInspectionsManage.$refs.grid.closeEdit();
                                return;
                            }
                            if (args.action == 'edit') {
                                this.ApiClient.Put("Inspection/" + args.data.Id, args.data, (response) => {
                                    this.$refs.gridInspectionsManage.$refs.grid.closeEdit();
                                    this.$refs.gridInspectionsManage.refresh();
                                    this.state.inspections.Update(response);
                                });
                            }
                            else if (args.action == 'add') {
                                this.ApiClient.Post("Inspection", args.data, (response) => {
                                    this.$refs.gridInspectionsManage.$refs.grid.closeEdit();
                                    this.$refs.gridInspectionsManage.refresh();
                                    this.state.inspections.Add(response);
                                });
                            }
                        }
                        else if (args.requestType == 'delete') {
                            args.cancel = true;

                            DialogObj = DialogUtility.confirm({
                                title: 'Delete Inspection',
                                content: 'Confirm you would like to delete ' + args.data.at(0).Text,
                                isModal: true,
                                closeOnEscape: true,
                                okButton: {
                                    icon: 'fa fa-trash',
                                    text: GridToolbarIds.Delete,
                                    cssClass: 'e-danger',
                                    click: () => {
                                        this.ApiClient.Delete("Inspection/" + args.data.at(0).Id, (response) => {
                                            DialogObj.hide();
                                            this.$refs.gridInspectionsManage.refresh();
                                            this.state.inspections.Update(response);
                                        });
                                    }
                                }
                            });
                        }
                    },
                    toolbarClick: (args) => {
                        if (args.item.id === GridToolbarIds.Add) {
                            this.$refs.gridInspectionsManage.$refs.grid.addRecord();
                        }
                    },
                },
                serializationsGrid: {
                    dataSource: this.ApiClient.DataSource("Serialization/DataSource"),
                    columns: [
                        {
                            field: 'Text',
                            headerText: 'Value',
                            type: 'string',
                            validationRules: { 
                                required: [true, "Serialization name is required"], 
                                maxLength: [250, "Serialization name must contain 250 or fewer characters"] 
                            }
                        },
                        {
                            field: 'Id',
                            isPrimaryKey: true,
                            visible: false
                        },
                        {
                            headerText: '',
                            commands: [
                                { type: 'Edit', title: 'Edit Serialization', buttonOption: { id: GridToolbarIds.Edit, iconCss: 'fa fa-pencil', cssClass: "d16-command e-info" } },
                                { type: 'Delete', title: 'Delete Serialization', buttonOption: { id: GridToolbarIds.Delete, iconCss: 'fa fa-trash', cssClass: "d16-command e-danger" } },
                                { type: 'Save', title: 'Delete Serialization', buttonOption: { id: GridToolbarIds.Save, iconCss: 'fa fa-save', cssClass: "d16-command e-success" } },
                                { type: 'Cancel', title: 'Cancel', buttonOption: { id: GridToolbarIds.Cancel, iconCss: 'fa fa-times', cssClass: "d16-command e-flat" } }
                            ],
                            maxWidth: 100
                        }
                    ],
                    toolbar: [
                        { text: 'Add', tooltipText: 'Add Serialization', id: GridToolbarIds.Add, prefixIcon: 'e-add' },
                    ],
                    editSettings: {
                        allowAdding: true,
                        allowEditing: true,
                        allowDeleting: true
                    },
                    actionBegin: (args) => {
                        if (args.requestType == 'save') {
                            args.cancel = true;
                            if (args.data.Text == args.previousData.Text) {
                                this.$refs.gridSerializationsManage.$refs.grid.closeEdit();
                                return;
                            }
                            if (args.action == 'edit') {
                                this.ApiClient.Put("Serialization/" + args.data.Id, args.data, (response) => {
                                    this.$refs.gridSerializationsManage.$refs.grid.closeEdit();
                                    this.$refs.gridSerializationsManage.refresh();
                                    this.state.serializations.Update(response);
                                });
                            }
                            else if (args.action == 'add') {
                                this.ApiClient.Post("Serialization", args.data, (response) => {
                                    this.$refs.gridSerializationsManage.$refs.grid.closeEdit();
                                    this.$refs.gridSerializationsManage.refresh();
                                    this.state.serializations.Add(response);
                                });
                            }
                        }
                        else if (args.requestType == 'delete') {
                            args.cancel = true;

                            DialogObj = DialogUtility.confirm({
                                title: 'Delete Serialization',
                                content: 'Confirm you would like to delete ' + args.data.at(0).Text,
                                isModal: true,
                                closeOnEscape: true,
                                okButton: {
                                    icon: 'fa fa-trash',
                                    text: GridToolbarIds.Delete,
                                    cssClass: 'e-danger',
                                    click: () => {
                                        this.ApiClient.Delete("Serialization/" + args.data.at(0).Id, (response) => {
                                            DialogObj.hide();
                                            this.$refs.gridSerializationsManage.refresh();
                                            this.state.serializations.Update(response);
                                        });
                                    }
                                }
                            });
                        }
                    },
                    toolbarClick: (args) => {
                        if (args.item.id === GridToolbarIds.Add) {
                            this.$refs.gridSerializationsManage.$refs.grid.addRecord();
                        }
                    },
                },
            }
        },
        mounted() {
            document.title = `Dept 16 - Dropdowns`;
        },
        methods: {
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    
</style>
