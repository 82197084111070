<template>
    <ejs-button id="backButton" v-on:click="goBack" class="mb-1"><span class="fa fa-arrow-left" /> Back To Job</ejs-button>
    <div class="e-card" style="width: 100%; left: 0; border: none;">
        <div class="panel-body">
            <div class="row">
                <div class="col-xs-12 text-center" style="color: red!important; font-size:16px">
                    Please sign and deliver this to the Incoming Work Bench in the Model Shop. No Job will be started without a proper Signature.
                </div>
            </div>
            <div class="row">
                <div class="col-8">
                    <dl class="dl-horizontal">
                        <dt>
                            Job #:
                        </dt>
                        <dd>
                            <p>{{Job.JobNumber ?? '-'}}</p>
                        </dd>

                        <dt>
                            Inspection:
                        </dt>

                        <dd style="width:initial">
                            {{Job.Inspection?.Text ?? "-"}}
                        </dd>

                        <dt>
                            Revision:
                        </dt>

                        <dd>
                            {{Job.Revision ?? "-"}}
                        </dd>

                        <dt>
                            Work Type:
                        </dt>

                        <dd>
                            {{Job.WorkType?.Type ?? "-"}}
                        </dd>

                        <div v-if="Job.WorkType?.AllowCmmUsers ?? false">
                            <dt>
                                CMM Contacts:
                            </dt>
                            <dd>
                                {{CmmContactDisplay}}
                            </dd>
                        </div>

                        <dt>
                            Part Number:
                        </dt>
                        <dd>
                            {{Job.PartNumber ?? "-"}}
                        </dd>
                        <dt>
                            Part Description:
                        </dt>

                        <dd>
                            {{Job.PartDescription ?? "-"}}
                        </dd>
                    </dl>
                </div>
                <div class="col-4">
                    <div v-if="Job.Qr" class="qrcode" v-bind:style="{ backgroundImage: 'url(data:image/png;base64,' + Job.Qr + ')', backgroundSize: '100% 100%' }" > </div>
                </div>
                <div class="col-xs-12">
                    <dl class="dl-horizontal">
                        <dt>
                            Due Date:
                        </dt>

                        <dd>
                            {{Job.DateDue ?? "-"}}
                        </dd>

                        <dt>
                            Charge #:
                        </dt>

                        <dd>
                            {{Job.ChargeNumber ?? "-"}}
                        </dd>

                        <dt>
                            CPI #:
                        </dt>

                        <dd>
                            {{Job.ProgramNumberAndSuffix ?? '-'}}
                        </dd>

                        <dt>
                            Requestor:
                        </dt>

                        <dd>
                            {{Job.Requestor?.FullName ?? "-"}}
                        </dd>

                        <dt>
                            Contact Number:
                        </dt>

                        <dd>
                            {{Job.ContactNumber ?? "-"}}
                        </dd>

                        <dt>
                            Quantity:
                        </dt>

                        <dd>
                            {{Job.Quantity ?? "-"}}
                        </dd>

                        <dt>
                            Serialization:
                        </dt>

                        <dd>
                            {{Job.Serialization?.Text ?? '-'}}
                        </dd>

                        <dt>
                            Blueprint:
                        </dt>

                        <dd v-if="Job.Blueprint">
                            Yes
                        </dd>
                        <dd v-else-if="!Job.Blueprint">
                            No
                        </dd>

                        <dt>
                            Cad Model:
                        </dt>

                        <dd v-if="Job.CadModel">
                            Yes
                        </dd>
                        <dd v-else-if="!Job.CadModel">
                            No
                        </dd>

                        <dt v-if="Job.DateCommitted">
                            Commit Date:
                        </dt>

                        <dd v-if="Job.DateCommitted">
                            {{Job.DateCommitted}}
                        </dd>

                        <dt v-if="Job.DateCompleted">
                            Date Completed:
                        </dt>

                        <dd v-if="Job.DateCompleted">
                            {{Job.DateCompleted}}
                        </dd>

                        <dt>
                            Product Line:
                        </dt>

                        <dd>
                            {{Job.ProductLine?.ProductLine1 ?? "-"}}
                        </dd>

                        <dt>
                            Comments:
                        </dt>

                        <dd>
                            {{Job.Comments ?? '-'}}
                        </dd>

                        <dt>
                            Name (Printed):
                        </dt>
                        <dd>
                            ______________________________________________
                        </dd>
                        <dt>
                            Signature:
                        </dt>
                        <dd>
                            ______________________________________________
                        </dd>
                    </dl>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>

</template>

<script>
    import { ButtonComponent } from "@syncfusion/ej2-vue-buttons";
    import * as dayjs from 'dayjs';
    import Toast from "../../modules/Toast.js";

    export default {
        name: 'PrintJob',
        inject: ['router', 'ApiClient'],
        components: {
            'ejs-button': ButtonComponent
        },
        props: {
            jobNumber: String
        },
        data() {
            return {
                Job: {
                    loaded: false,
                    ModelShopTrackerDto: {}
                }
            }
        },
        mounted() {
            Toast.Clear();
            let jobNumber = this.$route.params.jobNumber;
            document.title = `Dept 16 - Job #${jobNumber}`;
            this.ApiClient.Get('ModelShopTracker/JobNumber/' + jobNumber, (response) => {
                this.Job = response;
                if (this.Job.DateDue) {
                    this.Job.DateDue = dayjs(this.Job.DateDue).format('M/D/YYYY');
                }
                setTimeout(() => { window.print(); }, 500)
            });
        },
        unmounted() {
        },
        computed: {
            CmmContactDisplay() {
                if (this.Job.CmmContactUsers == null || this.Job.CmmContactUsers.length == 0) {
                    return "-";
                }
                return this.Job.CmmContactUsers.map(x => x.FullName).join(', ');
            }
        },
        methods: {
            goBack() {
                this.router.back();
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    h3 {
        margin: 40px 0 0;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        display: inline-block;
        margin: 0 10px;
    }

    a {
        color: #42b983;
    }

    .row {
        margin-bottom: 5px;
    }

    @media print {
        #backButton {
            display: none !important;
        }

        body {
            margin-top: 0mm;
        }
    }

    header {
        display: none;
    }

    .content-wrapper {
        margin-left: 0;
    }

    .header-fixed .content-wrapper > .content {
        padding-top: 10px;
    }

    .dl-horizontal dt {
        float: left;
        width: 160px;
        overflow: hidden;
        clear: left;
        text-align: right;
        white-space: nowrap;
        
    }

    .dl-horizontal dd {
        margin-left: 180px;
    }

    .dl-horizontal {
        display: table;
    }

    .panel {
        max-width: 700px;
        margin: 0 auto;
    }

    div.qrcode {
        border: 1px solid grey;
    }
</style>
