<template>
    <div class="card">
        <div class="card-header">
            Hours Tracker
        </div>
        <Grid ref="gridHourTracker"
              id="gridHourTracker"
              :dataSource='grid.dataSource'
              :columns="grid.columns"
              :toolbar="grid.toolbar"
              :aggregates="grid.aggregates"
              :height="grid.height"
              :showColumnChooser="true"
              :allowPaging="true"
              :enablePersistence="true"
              :pageSettings="grid.pageSettings"
              @toolbarClick="grid.toolbarClick" />
    </div>
</template>

<script>
    import Grid from "./shared/Grid.vue";
    import LinkTemplate from "./templates/LinkTemplate.vue";
    import { createApp } from "vue";
    import GridHeights from "../constants/GridHeights";
    import GridToolbarIds from "../constants/GridToolbarIds.js";
    import * as dayjs from 'dayjs';

    const app = createApp();

    var cLinkTemplate = app.component('LinkTemplate', {
        data: () => ({}),
        template: "<LinkTemplate :link='data.JobNumber'></LinkTemplate>",
        components: { "LinkTemplate": LinkTemplate }
    });

    export default {
        name: 'HourTracker',
        inject: ['ApiClient'],
        components: {
            Grid,
        },
        data() {
            return {
                grid: {
                    dataSource: [],
                    columns: [
                        {
                            field: 'JobNumber',
                            headerText: 'Job Number',
                            template: function () {
                                return { template: cLinkTemplate }
                            },
                        },
                        {
                            field: 'ChargeNumber',
                            headerText: 'Charge #',
                        },
                        {
                            field: 'Date',
                            headerText: 'Date',
                            type: 'date',
                            format: 'M/d/yyyy'
                        },
                        {
                            field: 'ActualHours',
                            headerText: 'Hours',
                            format: 'N2'
                        },
                        {
                            field: 'UserName',
                            headerText: 'User',
                        }
                    ],
                    toolbar: [
                        {
                            id: GridToolbarIds.CurrentWeek,
                            text: 'Current Week',
                            align: 'Center'
                        },
                    ],
                    aggregates: [
                        {
                            columns: [
                                {
                                    type: 'Sum',
                                    field: 'ActualHours',
                                    format: 'N2'
                                }
                            ]
                        }
                    ],
                    height: GridHeights.aggregates,
                    pageSettings: { pageSizes: [5, 10, 15, 25, 'All'], pageSize: 15 },
                    toolbarClick: (args) => {
                        if (args.item.id == GridToolbarIds.CurrentWeek) {
                            let monday = dayjs().weekday(1);
                            let sunday = monday.add(6, 'day');
                            this.$refs.gridHourTracker.$refs.grid.ej2Instances.filterSettings.columns = this.$refs.gridHourTracker.$refs.grid.ej2Instances.filterSettings.columns.filter(x => x.field != 'Date');
                            this.$refs.gridHourTracker.$refs.grid.ej2Instances.filterSettings.columns = this.$refs.gridHourTracker.$refs.grid.ej2Instances.filterSettings.columns.concat([
                                {
                                    field: 'Date',
                                    operator: 'greaterthanorequal',
                                    predicate: 'and',
                                    value: new Date(new Date(monday).toDateString())
                                },
                                {
                                    field: 'Date',
                                    operator: 'lessthanorequal',
                                    predicate: 'and',
                                    value: new Date(new Date(sunday).toDateString())
                                },
                            ]);
                        }
                    }
                },
            }
        },
        methods: {

        },
        provide: {

        },
        mounted() {
            document.title = 'Dept 16 - Hours';
            this.ApiClient.Get("Hour", (response) => {
                response.forEach(x => {
                    if (x.Date) {
                        x.Date = new Date(x.Date);
                    }
                })
                this.grid.dataSource = response;
            });
        }

    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        display: inline-block;
        margin: 0 10px;
    }

    a {
        color: #42b983;
    }
</style>
