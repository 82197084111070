<template>
    <div>
        <a href="#" class="grid-link" v-on:click="download">{{FileName}}</a>
    </div>
</template>

<script>
    import { saveAs } from 'file-saver';

    export default {
        inject: ['ApiClient'],
        components: {
        },
        mounted() {
        },
        data() {
            return {
            }
        },
        methods: {
            download: function () {
                this.ApiClient.Download("File/Download/" + this.JobFileId, null, (response, file) => {
                    saveAs(file);
                });
            }
        },
        props: {
            FileName: String,
            JobFileId: Number
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    a {
        color: unset;
    }
    .grid-link{
        text-decoration: none;
    }
</style>
