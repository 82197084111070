import { createApp } from 'vue'
import router from './modules/router/Router.js';
import App from './App.vue'
import config from './modules/Config.js';
import { registerLicense } from "@syncfusion/ej2-base";
import state from './modules/State.js';
import ApiClient from './modules/HttpClient.js';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js'
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as weekday from 'dayjs/plugin/weekday';
import { AppInsightsPlugin } from "vue3-application-insights";
import { msalPlugin } from "./plugins/MsalPlugin.js";
import { msalInstance } from "./modules/AuthConfig.js";
import { CustomNavigationClient } from "./modules/router/NavigationClient";


const navigationClient = new CustomNavigationClient(router);
msalInstance.setNavigationClient(navigationClient);

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

const aiOptions = {
    appName: "Dept16-Vue-Client",
    connectionString: config.Provider('app_insights_connection'),
    router: router,
    trackAppErrors: true,
    trackInitialPageView: true,
    onLoaded: function (sdk) {
        sdk.context.user.authenticatedId = state.UserId();
    }
};

dayjs.extend(utc).extend(weekday);

registerLicense(config.Provider("ej_license"));

const app = createApp(App)
    .use(bootstrap)
    .use(AppInsightsPlugin, aiOptions)
    .use(router)
    .use(msalPlugin, msalInstance)
    .provide("router", router)
    .provide("config", config)
    .provide("state", state)
    .provide("ApiClient", ApiClient);

router.isReady().then(() => {
    // Waiting for the router to be ready prevents race conditions when returning from a loginRedirect or acquireTokenRedirect
    app.mount('#app');
});