<template>
    <div class="loader flex">
        <div class="loader-inner">
            <div class="row">
                <div class="col-12">
                    <div class="spinner-border spinner-border-sm m-3"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <p><b>{{message ?? 'Loading'}} - Please Wait</b></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SpinnerComp',
        props: {
            message: String
        }
    }
</script>

<style scoped>

    .loader {
        height: 100%;
        width: 100%;
        background: white;
        z-index: 1200;
        text-align: center;
        box-sizing: border-box;
        padding-top: 10px;
    }

        .loader i {
            --fa-animation-direction: reverse;
        }
</style>