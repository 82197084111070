<template>
    <div style="width: 50%; min-width: 250px; max-width: 500px;">
        <div class="simple-card">
            <label style="display:flex; justify-content: start;">Model Shop Tracker Search</label>
            <div class="site-btn-group">
                <ejs-numerictextbox ref="number" v-model="number" format="##" decimals="0" :min="0" :step="1" :max="2147483647" placeholder="Enter a Job #" :showSpinButton="false" v-on:keyup="searchJob2"></ejs-numerictextbox>
                <ejs-button v-on:click="searchJob" :isPrimary="true"><span class="fa fa-search" /> Search</ejs-button>
            </div>
        </div>
    </div>
</template>

<script>
    import { ButtonComponent } from "@syncfusion/ej2-vue-buttons";
    import { NumericTextBoxComponent } from "@syncfusion/ej2-vue-inputs";

    export default {
        inject: ['router'],
        components: {
            'ejs-button': ButtonComponent,
            'ejs-numerictextbox': NumericTextBoxComponent,

        },
        name: 'HomePage',
        props: {
            msg: String
        },
        mounted() {
            document.title = 'Dept 16 - Model Shop';
        },
        data() {
            return {
                number: null
            }
        },
        methods: {
            searchJob: function () {
                if (this.number) {
                    this.router.push('/EditModelShop/' + this.number);
                }

            },
            searchJob2: function (args) {
                if (args.keyCode === 13) {
                    this.searchJob();

                }
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    
    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        display: inline-block;
        margin: 0 10px;
    }

    a {
        color: #42b983;
    }

    .searchbar-container {
        width: 50%;
        border: solid 2px;
        padding: 4px;
        margin-top: 30px;
    }

    .input-container {
        display: flex;
        justify-content: center;
    }
</style>
