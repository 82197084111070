import { reactive } from 'vue'
import { useLocalStorage } from '@vueuse/core';
import client from './HttpClient';
import { Query, Predicate } from "@syncfusion/ej2-data";

const myUser = useLocalStorage('dept16-user', {});
const dropdowns = useLocalStorage('dept16-dropdowns', { DateLoaded: null, Locations: [], Serializations: [], WorkTypes: [], ProductLines: [], Inspections: [], Roles: [] });

const state = {
    data: reactive({ SiteUser: myUser.value, Dropdowns: dropdowns.value }),
    SetUser(user) {
        state.data.SiteUser = user;
        myUser.value = user;
        return state.data.SiteUser;
    },
    UserIsSet() {
        return !!state.data.SiteUser.Email;
    },
    UserId() {
        return state.data.SiteUser?.ActorEmail ?? state.data.SiteUser?.Email;
    },
    RefreshUser() {
        client.Get("Me", (response) => {
            state.SetUser(response);
        });
    },
    GetDropdowns(forced) {
        let localDropdowns = dropdowns.value;

        // Load dropdowns if they've never been loaded or it's been more than 10 minutes since they were last loaded.
        if (forced === true || !localDropdowns.DateLoaded || (Math.abs(new Date() - new Date(localDropdowns.DateLoaded)) / 6e4) > 10) {
            client.Get(localDropdowns.DateLoaded ? 'Listing/Dropdowns/' + localDropdowns.DateLoaded : 'Listing/Dropdowns', (response) => {
                Object.keys(response).filter(x => Array.isArray(response[x])).forEach(x => {
                    let updatedList = response[x];

                    if (!updatedList.length) {
                        return;
                    }

                    let list = dropdowns.value[x];

                    if (list.length == 0) {
                        list = updatedList;
                    }
                    else {
                        let firstItem = list[0];
                        if (!Object.keys(firstItem).includes('Deleted')) {
                            list = updatedList;
                        }
                        else if (Object.keys(firstItem).includes('Id')) {
                            list = list.map(y => {
                                let matchedItem = updatedList.find(z => z.Id == y.Id);
                                return matchedItem ?? y;
                            });
                            updatedList.forEach(y => {
                                if (!list.find(z => z.Id == y.Id)) {
                                    list.push(y);
                                }
                            });
                        }
                        else {
                            list = list.map(y => {
                                let matchedItem = updatedList.find(z => z.Key == y.Key);
                                return matchedItem ?? y;
                            });
                            updatedList.forEach(y => {
                                if (!list.find(z => z.Key == y.Key)) {
                                    list.push(y);
                                }
                            });
                        }
                    }

                    dropdowns.value[x] = list;
                });
                dropdowns.value.DateLoaded = response.DateLoaded;
                state.data.Dropdowns = dropdowns.value;
            });
        }

        return localDropdowns;
    },
    locations: {
        Add(item) {
            let local = dropdowns.value.Locations;

            local.push(item);

            dropdowns.value.Locations = local;
            state.data.Dropdowns = dropdowns.value;
        },
        Update(item) {
            let local = dropdowns.value.Locations;

            local = local.map(x => {
                return x.Id == item.Id ? item : x;
            });

            dropdowns.value.Locations = local;
            state.data.Dropdowns = dropdowns.value;
        }
    },
    inspections: {
        Add(item) {
            let local = dropdowns.value.Inspections;

            local.push(item);

            dropdowns.value.Inspections = local;
            state.data.Dropdowns = dropdowns.value;
        },
        Update(item) {
            let local = dropdowns.value.Inspections;

            local = local.map(x => {
                return x.Id == item.Id ? item : x;
            });

            dropdowns.value.Inspections = local;
            state.data.Dropdowns = dropdowns.value;
        }
    },
    serializations: {
        Add(item) {
            let local = dropdowns.value.Serializations;

            local.push(item);

            dropdowns.value.Serializations = local;
            state.data.Dropdowns = dropdowns.value;
        },
        Update(item) {
            let local = dropdowns.value.Serializations;

            local = local.map(x => {
                return x.Id == item.Id ? item : x;
            });

            dropdowns.value.Serializations = local;
            state.data.Dropdowns = dropdowns.value;
        }
    },
    predicateBuilder(id) {
        let query = new Query().take(50);
        let pred = new Predicate('Deleted', 'equal', false).or('Id', 'equal', id);

        return query.where(pred);
    }
};

export default state;