<template>
    <div class="mx-auto mt-3" style="width: 400px;">
        <div class="card">
            <div class="card-header">Logout</div>
            <div class="card-body">
                You logged out.
            </div>
        </div>
    </div>
</template>

<script>

    import router from "../../modules/router/Router.js";
    import state from "../../modules/State.js";

    export default {
        components: {
        },
        name: 'LogoutPage',
        props: {

        },
        mounted() {
            // Clear stored user data
            state.SetUser({});

            // Clear session storage
            sessionStorage.clear();

            // Clear local storage
            localStorage.clear();

            // Clear all cookies (by setting expiration data)
            var cookies = document.cookie.split("; ");
            for (var c = 0; c < cookies.length; c++) {
                var d = window.location.hostname.split(".");
                while (d.length > 0) {
                    var cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
                    var p = location.pathname.split('/');
                    document.cookie = cookieBase + '/';
                    while (p.length > 0) {
                        document.cookie = cookieBase + p.join('/');
                        p.pop();
                    }
                    d.shift();
                }
            }

            // Go back to the home page
            router.push({ name: 'HomePage' });
        },
        data() {
            return {
            }
        },
        methods: {
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
