<template>
    <div>
        <article class="timeline-entry">
            <div class="timeline-entry-inner">
                <span class="timeline-time">
                    <span>{{time}}</span>
                    <span>{{date}}</span>
                </span>
                <div class="timeline-icon bg-success">
                    <i class="fa fa-calendar-o"></i>
                </div>
                <div class="timeline-label">
                    <h4 class="title">
                        <span>{{item?.User?.FullName}}</span> moved model to <span>{{item?.Location?.Name ?? ""}}</span>
                    </h4>
                </div>
            </div>
        </article>
    </div>
</template>

<script>
    import * as dayjs from 'dayjs';

    export default {
        components: {
            
        },
        props: {
            item: Object ?? {},
        },
        computed: {
            time() {
                let val = dayjs.utc(this.item.DateChanged).local().format('h:mm A')
                return val;
            },
            date() {
                let val = dayjs.utc(this.item.DateChanged).local().format('M/D/YYYY')
                return val;
            }
        },
        mounted() {
        },
        data() {
            return {
                data: {}
            }
        },
        methods: {
           
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    @import "../../assets/style/style.css";

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        display: inline-block;
        margin: 0 10px;
    }

    a {
        color: #42b983;
    }

   
</style>
