<template>
    <div class="e-avatar">
        <img v-if="userInfo.Avatar != '' && userInfo.Avatar != null" :src="'data:image/jpeg;base64,' + userInfo.Avatar" />
        <img v-else src="/img/profile.png" />
    </div>
    <div class="userListingItem">
        {{userInfo.FullName}}<br>
        <div>
            <div class="userListingItemDetails">NetId: {{userInfo.NetidDisplay}}</div>
            <div class="userListingItemDetails">Email: {{userInfo.Email}}</div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            userInfo: Object
        },
        mounted() {
        },
        data() {
            return {
            }
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
