<template>
    <div class="card">
        <div class="card-header">
            Prototype Tracker
        </div>
        <Grid ref="gridPrototypeTracker"
              id="gridPrototypeTracker"
              :dataSource="grid.dataSource"
              :toolbar="grid.toolbar"
              :query="grid.query"
              :columns="grid.columns"
              :height="grid.height"
              :showColumnChooser="true"
              :enablePersistence="true"
              :allowReordering="true"
              :filterSettings="grid.filterSettings"
              :sortSettings="grid.sortSettings"
              @commandClick="grid.commandClick"
              @toolbarClick="grid.toolbarClick"
              @queryCellInfo="grid.queryCellInfo"
              @rowDataBound="grid.rowDataBound"
              @recordDoubleClick="grid.recordDoubleClick" />
    </div>
</template>

<script>
    import Grid from "../shared/Grid.vue";
    import GridToolbarIds from '../../constants/GridToolbarIds';
    import GridHeights from "../../constants/GridHeights";
    import { Query } from "@syncfusion/ej2-data";
    import { DialogUtility } from '@syncfusion/ej2-vue-popups';

    let DialogObj = undefined;

    export default {
        name: 'PrototypeTracker',
        inject: ['router', 'state', 'ApiClient'],
        components: {
            Grid
        },
        expose: ['load'],
        mounted() {
            document.title = 'Dept 16 - Prototype';
        },
        data() {
            return {
                grid: {
                    dataSource: this.ApiClient.DataSource("ModelShopTracker/DataSource"),
                    query: new Query().addParams('prototype', true),
                    toolbar: [
                        { text: 'Create New', tooltipText: 'Create New Job', id: GridToolbarIds.Add, prefixIcon: 'e-add', },
                    ],
                    columns: [
                        {
                            field: 'JobNumber',
                            headerText: 'Job Number',
                        },
                        {
                            field: 'StatusDisplay',
                            headerText: 'Status',
                        },
                        {
                            field: 'WorkType',
                            headerText: 'Work Type',
                        },
                        {
                            field: 'LocationName',
                            headerText: 'Location',
                        },
                        {
                            field: 'Requestor',
                            headerText: 'Requestor',
                        },
                        {
                            field: 'PartNumber',
                            headerText: 'Part Number',
                        },
                        {
                            field: 'TechnicianDisplay',
                            headerText: 'Technicians',
                            allowFiltering: false,
                            allowSorting: false,
                            maxWidth: 300
                        },
                        {
                            field: 'PartDescription',
                            headerText: 'Part Description',
                        },
                        {
                            headerText: '',
                            commands: [
                                { type: "", title: 'Edit Job', buttonOption: { id: GridToolbarIds.Edit, iconCss: 'fa fa-pencil', cssClass: "d16-command e-info" } },
                                { type: "", title: 'View Job', buttonOption: { id: GridToolbarIds.View, iconCss: 'fa fa-file', cssClass: "d16-command e-flat" } },
                                { type: "", title: 'Delete Job', buttonOption: { id: GridToolbarIds.Delete, iconCss: 'fa fa-trash', cssClass: "d16-command e-danger d16-delete" } }
                            ],
                            showInColumnChooser: false
                        }
                    ],
                    filterSettings: {
                        type: GridToolbarIds.Excel,
                        columns: [
                            {
                                field: 'StatusDisplay',
                                operator: 'equal',
                                predicate: 'or',
                                value: 'New'
                            },
                            {
                                field: 'StatusDisplay',
                                operator: 'equal',
                                predicate: 'or',
                                value: 'Active'
                            },
                        ]
                    },
                    sortSettings: {
                        columns: [
                            {
                                field: 'StatusDisplay',
                                direction: 'Ascending'
                            }
                        ]
                    },
                    height: GridHeights.base,
                    queryCellInfo: (args) => {
                        if (args.column.field == 'StatusDisplay' && args.data.StatusColor) {
                            args.cell.classList.add('statusColor-' + args.data.StatusColor);
                        }
                    },
                    toolbarClick: (args) => {
                        if (args.item.id === GridToolbarIds.Add) {
                            this.router.push("/AddPrototype")
                        }
                    },
                    commandClick: (args) => {
                        if (args.commandColumn.buttonOption.id == GridToolbarIds.Edit) {
                            this.router.push("/EditModelShop/" + args.rowData.JobNumber)
                        } else if (args.commandColumn.buttonOption.id == GridToolbarIds.View) {
                            this.router.push("/ViewModelShop/" + args.rowData.JobNumber)
                        } else if (args.commandColumn.buttonOption.id == GridToolbarIds.Delete) {
                            DialogObj = DialogUtility.confirm({
                                title: 'Delete Job',
                                content: "Are you sure you would like to delete this job?",
                                isModal: true,
                                closeOnEscape: true,
                                okButton: {
                                    icon: 'fa fa-trash',
                                    text: GridToolbarIds.Delete,
                                    cssClass: 'e-danger',
                                    click: () => {
                                        this.ApiClient.Delete("ModelShopTracker/" + args.rowData.Id, () => {
                                            DialogObj.hide();
                                            this.$refs.gridPrototypeTracker.refresh();
                                        });
                                    }
                                }
                            });
                        }
                    },
                    rowDataBound: (args) => {
                        if (!this.SiteUser?.Abilities?.CanCoordinate) {
                            args.row.querySelector('.d16-delete').style.display = 'none';
                        }
                    },
                    recordDoubleClick: (args) => {
                        this.router.push("/EditModelShop/" + args.rowData.JobNumber);
                    }
                },
            }
        },
        computed: {
            SiteUser() {
                return this.state.data.SiteUser;
            },
        },
        methods: {
            load: function () {
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        display: inline-block;
        margin: 0 10px;
    }

    a {
        color: #42b983;
    }
</style>
