<template>
    <ejs-dialog ref="userDialog" :position="position" :visible="dialogVisible" :isModal="true" :buttons="buttons" header="Add Requestor" :width="500" @beforeClose="beforeClose">
        <Spinner v-show="saving" message="Saving"></Spinner>
        <div v-show="!saving" class="row mb-2 gx-2">
            <div class="col-8">
                <ejs-dropdownlist ref="ddlAddRequestor"
                                  :dataSource="dropdown.dataSource"
                                  :query="dropdown.query"
                                  :fields="dropdown.fields"
                                  :allowFiltering="true"
                                  filterType="Contains"
                                  popupWidth="Auto"
                                  placeholder="New Requestor"
                                  v-model:value="selectedId"
                                  @filtering="dropdown.filtering"
                                  :itemTemplate="'userTemplate'">
                    <template v-slot:userTemplate="{data}">
                        <UserTemplate :userInfo="data" />
                    </template>
                </ejs-dropdownlist>
            </div>
            <div class="col-4">
                <ejs-button v-on:click="addRequestor">Make Requestor</ejs-button>
            </div>
        </div>
    </ejs-dialog>
</template>

<script>
    import Toast from "../../modules/Toast.js";
    import { DialogComponent } from "@syncfusion/ej2-vue-popups";
    import { DropDownListComponent } from "@syncfusion/ej2-vue-dropdowns";
    import { ButtonComponent } from "@syncfusion/ej2-vue-buttons";
    import { Query, Predicate } from "@syncfusion/ej2-data";
    import UserTemplate from '../templates/UserTemplate.vue';
    import Spinner from '../shared/Spinner.vue';

    export default {
        name: 'AddRequestor',
        inject: ['ApiClient'],
        expose: ['show'],
        emits: ['requestorAdded'],
        components: {
            'ejs-dialog': DialogComponent,
            'ejs-dropdownlist': DropDownListComponent,
            'ejs-button': ButtonComponent,
            UserTemplate,
            Spinner
        },
        data() {
            return {
                dropdown: {
                    dataSource: this.ApiClient.DataSource("Listing/Users"),
                    query: new Query().where('IsRequestor', 'equal', false).take(50),
                    fields: { 
                        text: "FullName", 
                        value: "Id" 
                    },
                    filtering: (e) => {
                        let ds = this.ApiClient.DataSource("Listing/Users");
                        let query = new Query().where('IsRequestor', 'equal', false).take(50);
                        let pred = new Predicate(true);

                        if (e.text != null && e.text != '') {
                            pred = new Predicate('FullName', 'contains', e.text, true);
                            pred = pred.or('NetidDisplay', 'contains', e.text, true);
                            pred = pred.or('Email', 'contains', e.text, true);
                        }

                        query = query.where(pred);

                        e.updateData(ds, query);
                    },
                },
                buttons: [{ click: this.closeDialog, buttonModel: { content: 'Close' } }],
                dialogVisible: false,
                saving: false,
                position: { X: "center", Y: "top" },
                selectedId: null,
            }
        },
        mounted() {
        },
        computed: {
        },
        methods: {
            show: function () {
                this.dialogVisible = true;
                this.selectedId = null;
            },
            addRequestor: function () {
                if (!(this.selectedId > 0)) {
                    Toast.Warning("Please make a selection before adding user as requestor");
                    return;
                }
                this.saving = true;
                this.ApiClient.Put('User/' + this.selectedId + '/Requestor', null, (response) => {
                    this.closeDialog();
                    this.$emit("requestorAdded", response);
                }, () => {
                    this.saving = false;
                });
            },
            closeDialog: function () {
                this.dialogVisible = false;
            },
            beforeClose: function () {
                this.closeDialog();
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
