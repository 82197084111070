<template>
    <div class="card" id="lockout-form">
        <div class="card-header">
            Required Attachments
        </div>
        <Grid ref="gridRequiredAttachmentsManage"
              id="gridRequiredAttachmentsManage"
              :dataSource="grid.dataSource"
              :query="grid.query"
              :columns="grid.columns"
              :toolbar="grid.toolbar"
              :height="grid.height"
              :editSettings="grid.editSettings"
              @actionBegin="grid.actionBegin"
              @toolbarClick="grid.toolbarClick"
              @created="grid.created" />
    </div>
</template>

<script>
    import { DropDownList } from "@syncfusion/ej2-vue-dropdowns";
    import GridToolbarIds from "../../constants/GridToolbarIds";
    import Grid from "../shared/Grid.vue";
    import GridHeights from "../../constants/GridHeights";
    import { DialogUtility } from '@syncfusion/ej2-vue-popups';
    import { Query } from "@syncfusion/ej2-data";

    let DialogObj = undefined;
    let gridRef, ddlWorkType;

    export default {
        name: 'RequiredAttachments',
        inject: ['ApiClient', 'state'],
        components: {
            Grid
        },
        props: {

        },
        data() {
            return {
                grid: {
                    dataSource: this.ApiClient.DataSource("RequiredFileType/DataSource"),
                    query: new Query().where('WorkTypeId', 'equal', null),
                    columns: [
                        {
                            field: 'Name',
                            headerText: 'Name',
                            type: 'string',
                            validationRules: {
                                required: [true, "Name is required"],
                                maxLength: [150, "Name must be between 1 and 150 characters"]
                            },
                        },
                        {
                            field: 'Id',
                            isPrimaryKey: true,
                            visible: false,
                            showInColumnChooser: false,
                        },
                        {
                            headerText: '',
                            commands: [
                                { type: 'Edit', title: 'Edit Required File Type', buttonOption: { id: GridToolbarIds.Edit, iconCss: 'fa fa-pencil', cssClass: "d16-command e-info" } },
                                { type: 'Delete', title: 'Delete Required File Type', buttonOption: { id: GridToolbarIds.Delete, iconCss: 'fa fa-trash', cssClass: "d16-command e-danger" } },
                                { type: 'Save', title: 'Delete Required File Type', buttonOption: { id: GridToolbarIds.Save, iconCss: 'fa fa-save', cssClass: "d16-command e-success" } },
                                { type: 'Cancel', title: 'Cancel', buttonOption: { id: GridToolbarIds.Cancel, iconCss: 'fa fa-times', cssClass: "d16-command e-flat" } }
                            ],
                            showInColumnChooser: false,
                            maxWidth: 100
                        }
                    ],
                    toolbar: [
                        { text: 'Add', tooltipText: 'Add Job Lockout', id: GridToolbarIds.Add, prefixIcon: 'e-add' },
                        {
                            template: '<input type="text" id="ddlWorkType" />',
                            align: 'Center'
                        },
                    ],
                    editSettings: {
                        allowAdding: true,
                        allowEditing: true,
                        allowDeleting: true
                    },
                    height: GridHeights.base,
                    actionBegin: (args) => {
                        if (args.requestType == 'save') {
                            args.cancel = true;
                            if (args.data.Name == args.previousData.Name) {
                                this.$refs.gridRequiredAttachmentsManage.$refs.grid.closeEdit();
                                return;
                            }
                            if (args.action == 'edit') {
                                this.ApiClient.Put("RequiredFileType/" + args.data.Id, args.data, () => {
                                    this.$refs.gridRequiredAttachmentsManage.$refs.grid.closeEdit();
                                    this.$refs.gridRequiredAttachmentsManage.refresh();
                                });
                            }
                            else if (args.action == 'add') {
                                args.data.WorkTypeId = ddlWorkType.value;
                                args.data.WorkType = ddlWorkType.itemData;
                                this.ApiClient.Post("RequiredFileType", args.data, () => {
                                    this.$refs.gridRequiredAttachmentsManage.$refs.grid.closeEdit();
                                    this.$refs.gridRequiredAttachmentsManage.refresh();
                                });
                            }
                        }
                        else if (args.requestType == 'delete') {
                            args.cancel = true;


                            DialogObj = DialogUtility.confirm({
                                title: 'Delete Required Attachment',
                                content: "Confirm you would like to delete " + args.data.at(0).Name,
                                isModal: true,
                                closeOnEscape: true,
                                okButton: {
                                    icon: 'fa fa-trash',
                                     text: GridToolbarIds.Delete,
                                    cssClass: 'e-danger',
                                    click: () => {
                                        this.ApiClient.Delete('RequiredFileType/' + args.data.at(0).Id, () => {
                                            DialogObj.hide();
                                            this.$refs.gridRequiredAttachmentsManage.refresh();
                                        });
                                    }
                                }
                            });
                        }
                    },
                    toolbarClick: (args) => {
                        if (args.item.id === GridToolbarIds.Add) {
                            this.$refs.gridRequiredAttachmentsManage.$refs.grid.addRecord();
                        }
                    },
                    created: () => {
                        gridRef = this.grid;
                        ddlWorkType = new DropDownList({
                            dataSource: this.state.data.Dropdowns.WorkTypes,
                            fields: {
                                value: 'Id',
                                text: 'Type'
                            },
                            allowFiltering: true,
                            filterType: "Contains",
                            placeholder: 'Work Type',
                            enablePersistence: true,
                            change: function (args) {
                                gridRef.query = new Query().where('WorkTypeId', 'equal', args.value);
                            }
                        });
                        ddlWorkType.appendTo('#ddlWorkType');
                    }
                },
            }
        },
        mounted() {
            document.title = `Dept 16 - Required Attachments`;
            this.state.GetDropdowns();
        },
        methods: {
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
