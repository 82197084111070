<template>
    <div class="card">
        <div class="card-header">
            Locations
        </div>
        <Grid ref="gridLocationManage"
              id="gridLocationManage"
              :dataSource="grid.dataSource"
              :columns="grid.columns"
              :toolbar="grid.toolbar"
              :height="grid.height"
              :editSettings="grid.editSettings"
              @actionBegin="grid.actionBegin"
              @actionComplete="grid.actionComplete"
              @commandClick="grid.commandClick"
              @toolbarClick="grid.toolbarClick" />
    </div>
</template>

<script>

    import GridToolbarIds from "../../constants/GridToolbarIds.js";
    import GridHeights from "../../constants/GridHeights";
    import Grid from "../shared/Grid.vue";
    import { DialogUtility } from '@syncfusion/ej2-vue-popups';

    let DialogObj = undefined;

    export default {
        name: 'location-component',
        inject: ['router', 'state', 'ApiClient'],
        components: {
            Grid,
        },
        props: {

        },
        data() {
            return {
                grid: {
                    dataSource: this.ApiClient.DataSource("Location/DataSource"),
                    columns: [
                        {
                            field: 'Name',
                            headerText: 'Name',
                            validationRules: {
                                required: [true, "Location name is required"], 
                                maxLength: [50, "Location name must contain 50 or fewer characters"]
                            }
                        },
                        {
                            field: 'Id',
                            isPrimaryKey: true,
                            visible: false,
                            showInColumnChooser: false,
                        },
                        {
                            headerText: '',
                            commands: [
                                { type: "Edit", title: 'Edit Location', buttonOption: { id: GridToolbarIds.Edit, iconCss: 'fa fa-pencil', cssClass: "d16-command e-info" } },
                                { type: "", title: 'Print', buttonOption: { id: GridToolbarIds.Print, iconCss: 'fa fa-print', cssClass: "d16-command e-flat d16-print" } },
                                { type: "Delete", title: 'Delete Location', buttonOption: { id: GridToolbarIds.Delete, iconCss: 'fa fa-trash', cssClass: "d16-command e-danger" } },
                                { type: 'Save', title: 'Delete Location', buttonOption: { id: GridToolbarIds.Save, iconCss: 'fa fa-save', cssClass: "d16-command e-success" } },
                                { type: 'Cancel', title: 'Cancel', buttonOption: { id: GridToolbarIds.Cancel, iconCss: 'fa fa-times', cssClass: "d16-command e-flat" } }
                            ],
                            showInColumnChooser: false
                        }
                    ],
                    toolbar: [
                        { text: 'Add', tooltipText: 'Add Location', id: GridToolbarIds.Add, prefixIcon: 'e-add' },
                    ],
                    editSettings: {
                        allowAdding: true,
                        allowEditing: true,
                        allowDeleting: true
                    },
                    height: GridHeights.base,
                    actionBegin: (args) => {
                        if (args.requestType == 'save') {
                            args.cancel = true;
                            if (args.data.Name == args.previousData.Name) {
                                this.$refs.gridLocationManage.$refs.grid.closeEdit();
                                return;
                            }
                            if (args.action == 'edit') {
                                this.ApiClient.Put("Location/" + args.data.Id, args.data, (response) => {
                                    this.$refs.gridLocationManage.$refs.grid.closeEdit();
                                    this.$refs.gridLocationManage.refresh();
                                    this.state.locations.Update(response);
                                });
                            }
                            else if (args.action == 'add') {
                                this.ApiClient.Post("Location", args.data, (response) => {
                                    this.$refs.gridLocationManage.$refs.grid.closeEdit();
                                    this.$refs.gridLocationManage.refresh();
                                    this.state.locations.Add(response);
                                });
                            }
                        }
                        else if (args.requestType == 'delete') {
                            args.cancel = true;
                            DialogObj = DialogUtility.confirm({
                                title: 'Delete Location',
                                content: "Confirm you would like to delete " + args.data.at(0).Name,
                                isModal: true,
                                closeOnEscape: true,
                                okButton: {
                                    icon: 'fa fa-trash',
                                     text: GridToolbarIds.Delete,
                                    cssClass: 'e-danger',
                                    click: () => {
                                        this.ApiClient.Delete("Location/" + args.data.at(0).Id, (response) => {
                                            DialogObj.hide();
                                            this.$refs.gridLocationManage.refresh();
                                            this.state.locations.Update(response);
                                        });
                                    }
                                }
                            });
                        }
                    },
                    actionComplete: (args) => {
                        if (args.requestType == 'beginEdit' || args.requestType == 'add') {
                            args.row.querySelector('.d16-print').style.display = 'none';
                        }
                    },
                    toolbarClick: (args) => {
                        if (args.item.id === GridToolbarIds.Add) {
                            this.$refs.gridLocationManage.$refs.grid.addRecord();
                        }
                    },
                    commandClick: (args) => {
                        if (args.commandColumn.buttonOption.id == GridToolbarIds.Print) {
                            this.router.push('/Location/Print/' + args.rowData.Id)
                        }
                    },
                },                
            }
        },
        mounted() {
            document.title = `Dept 16 - Locations`;
            this.state.GetDropdowns();
        },
        methods: {
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
